import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  termoContent: {
    padding: 10,  
    textAlign: 'justify',  
    position: 'relative',  
    height: '100%', 
    overflowX: 'hidden', 
    overflowY: 'scroll'
  }
}));

export default function TermoDeUsoPage() {
  const classes = useStyles();
  return (
    <div className={classes.termoContent}>
      <h1 style={{fontWeight: 'bold'}}>Termo e Condi&ccedil;&otilde;es de Uso</h1>
      <p><strong>&nbsp;</strong></p>
      <p>
        Este Termo e Condi&ccedil;&otilde;es de Uso e outras Aven&ccedil;as (&ldquo;Contrato&rdquo;) aplicam-se ao uso dos servi&ccedil;os oferecidos pela <strong>SNAKE SOFTWARE SYSTEMS TECNOLOGIA INC LTDA</strong>, pessoa jur&iacute;dica de
        direito privado, inscrita no CNPJ sob o n&ordm; 34.345.128/0001-09, com estabelecimento na Rua Ant&ocirc;nio de Albuquerque, 330 901 &ndash; Savassi, Belo Horizonte &ndash; MG, 30112-020&nbsp;(&ldquo;<strong>SNAKE</strong>&rdquo;),
        acessada pelo site www.snakeapp.com, cujos servi&ccedil;os respeitar&atilde;o os dispositivos do presente Contrato, doravante denominada Contratada (pessoa f&iacute;sica ou jur&iacute;dica), maior e capaz, que tenha preenchido o
        Cadastro, cujos dados passam a ser parte integrante deste instrumento, bem como que tenha &ldquo;consentido&rdquo; eletronicamente todas as cl&aacute;usulas do mesmo e todas as demais pol&iacute;ticas dispon&iacute;veis no site,
        doravante denominado Contratante.
      </p>
      <p>&nbsp;</p>
      <p>
        Os servi&ccedil;os dispon&iacute;veis da <strong>SNAKE</strong>, atrav&eacute;s de acesso no site www.snakeapp.com, bem como todos os seus subdom&iacute;nios e aplicativos para os sistemas operacionais m&oacute;veis iOS e Android,
        ser&atilde;o regidos pelas cl&aacute;usulas e condi&ccedil;&otilde;es abaixo.
      </p>
      <p>&nbsp;</p>
      <p>
        Ao consentir eletronicamente o presente Contrato, atrav&eacute;s do clique no bot&atilde;o &ldquo;Aceito o Termo e Condi&ccedil;&otilde;es de Uso e outras Aven&ccedil;as&rdquo;, bem como a&nbsp;&ldquo;Pol&iacute;tica de
        Privacidade&rdquo; da p&aacute;gina de cadastro complementar a esta, a Contratada estar&aacute; automaticamente consentindo expressamente e concordando em se submeter integralmente aos termos e condi&ccedil;&otilde;es, tanto do Termo
        de&nbsp;e de quaisquer de suas altera&ccedil;&otilde;es futuras, al&eacute;m de aceitar as disposi&ccedil;&otilde;es das pol&iacute;ticas da plataforma digital.
      </p>
      <p>&nbsp;</p>
      <p><strong>GLOSS&Aacute;RIO</strong>:</p>
      <p>&nbsp;</p>
      <p>
        <strong>ASSINATURA DIGITAL </strong>&ndash; tipo de assinatura eletr&ocirc;nica que usa opera&ccedil;&otilde;es matem&aacute;ticas com base em algoritmos criptogr&aacute;ficos de criptografia assim&eacute;trica para garantir
        seguran&ccedil;a na autenticidade das documenta&ccedil;&otilde;es. Para assinar digitalmente um documento &eacute; necess&aacute;rio possuir um certificado digital. Entre as principais vantagens do uso de assinatura digital est&atilde;o
        o n&atilde;o rep&uacute;dio (n&atilde;o deixa d&uacute;vidas quanto ao seu remetente) e tempestividade (a AC pode verificar data e hora da assinatura de um documento);
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ASSINATURA ELETR&Ocirc;NICA</strong> &ndash; nome dados aos mecanismos que permitem a assinatura de documentos virtuais com validade jur&iacute;dica. A legisla&ccedil;&atilde;o brasileira disciplinou a assinatura
        eletr&ocirc;nica, de forma ampla, atrav&eacute;s da Medida Provis&oacute;ria 2002-2/2001;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ASSINATURA</strong> <strong>SNAKE</strong>: contrata&ccedil;&atilde;o do software, realizada ap&oacute;s o cadastro no site www.snakeapp.com, para que a Contratante possa usufruir das funcionalidades do software
        <strong>SNAKE</strong> oferecidas no plano contratado.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>AUTENTICA&Ccedil;&Atilde;O</strong> &ndash; processo que busca verificar a identidade digital de uma entidade de um sistema no momento em que ela requisita acesso a esse sistema. O processo &eacute; realizado por meio de regras
        preestabelecidas, geralmente pela compara&ccedil;&atilde;o das credenciais apresentadas pela entidade com outras j&aacute; pr&eacute;-definidas no sistema, reconhecendo como verdadeiras ou leg&iacute;timas as partes envolvidas em um
        processo;
      </p>
      <p>&nbsp;</p>
      <p><strong>AUTENTICA&Ccedil;&Atilde;O DE DOIS FATORES (2FA)</strong> &ndash; processo de seguran&ccedil;a que exige que os usu&aacute;rios forne&ccedil;am dois meios de identifica&ccedil;&atilde;o antes de acessarem suas contas;</p>
      <p>&nbsp;</p>
      <p>
        <strong>CADASTRO OU CREDENCIAMENTO</strong> &ndash; processo pelo qual o usu&aacute;rio recebe credenciais de seguran&ccedil;a que conceder&atilde;o o acesso, incluindo a identifica&ccedil;&atilde;o, a autentica&ccedil;&atilde;o, o
        cadastramento de c&oacute;digo de identifica&ccedil;&atilde;o e defini&ccedil;&atilde;o de perfil de acesso em fun&ccedil;&atilde;o de autoriza&ccedil;&atilde;o pr&eacute;via e da necessidade de conhecer;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>CREDENCIAMENTO DE SEGURAN&Ccedil;A</strong> &ndash; processo utilizado para habilitar &oacute;rg&atilde;o ou entidade p&uacute;blica ou privada ou para credenciar pessoa, para o tratamento de informa&ccedil;&atilde;o
        classificada;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>SOFTWARE</strong> <strong>SNAKE</strong> &ndash;&nbsp;Programa de computador &eacute; a express&atilde;o de um conjunto organizado de instru&ccedil;&otilde;es em linguagem natural ou codificada, contida em suporte f&iacute;sico
        de qualquer natureza, de emprego necess&aacute;rio em m&aacute;quinas autom&aacute;ticas de tratamento da informa&ccedil;&atilde;o, dispositivos, instrumentos ou equipamentos perif&eacute;ricos, baseados em t&eacute;cnica digital ou
        an&aacute;loga, para faz&ecirc;-los funcionar de modo e para fins determinados. O sistema de processamento de dados que tem como foco auxiliar no gerenciamento de informa&ccedil;&otilde;es m&eacute;dicas dos pacientes da Contratante,
        com diversos recursos de controle de consultas e lembretes, cadastro de pacientes, seus hist&oacute;ricos, customiza&ccedil;&atilde;o de prontu&aacute;rios, acesso a bul&aacute;rios e a informa&ccedil;&otilde;es sobre doen&ccedil;as,
        devidamente atualizadas, al&eacute;m de um sistema de controle financeiro com ferramentas de faturamento, dentre outras que podem ser adicionadas ao software <strong>SNAKE</strong> para facilitar a gest&atilde;o de uma cl&iacute;nica e
        do cotidiano dos m&eacute;dicos e outros profissionais de sa&uacute;de;
      </p>
      <p>&nbsp;</p>
      <p><strong>LOGIN</strong>: trata-se do endere&ccedil;o de e-mail, informado pela Contratante no ato do seu cadastro, que usar&aacute; para acessar o software <strong>SNAKE</strong> e o seu cadastro;</p>
      <p>&nbsp;</p>
      <p><strong>MENSALIDADE</strong>: valor devido pela Contratante que queira utilizar o software <strong>SNAKE</strong>, conforme o Plano escolhido pela Contratante no ato do credenciamento;</p>
      <p>&nbsp;</p>
      <p>
        <strong>SENHA</strong>: sequ&ecirc;ncia de letras e n&uacute;meros escolhida pela Contratante para gerar uma autentica&ccedil;&atilde;o de dois fatores. A senha &eacute; pessoal e intransfer&iacute;vel, sendo que somente a Contratante
        poder&aacute; utiliz&aacute;-la. A senha ser&aacute; composta dentro dos padr&otilde;es especificados no site www.snakeapp.com;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>SITE</strong>: plataforma digital da <strong>SNAKE</strong>, localizado no endere&ccedil;o www.snakeapp.com e seus subdom&iacute;nios, por meio do qual a Contratante poder&aacute; acessar o software <strong>SNAKE</strong> e
        solicitar a disponibiliza&ccedil;&atilde;o do servi&ccedil;o, mediante credenciamento, informa&ccedil;&atilde;o de login e gera&ccedil;&atilde;o da SENHA pessoal e intransfer&iacute;vel de acesso pr&oacute;prio.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA PRIMEIRA &ndash; OBJETO E ESCOPO</strong></p>
      <p>&nbsp;</p>
      <p>
        1.1 Constitui objeto do presente contrato a concess&atilde;o, pela Contratada, da licen&ccedil;a do direito de uso &agrave; Contratante de 1 (uma) &uacute;nica c&oacute;pia do sistema de programa de computador (software) denominado
        <strong>SNAKE</strong>, de propriedade intelectual e autoral da Contratada, devidamente registrado no INPI &ndash; Instituto Nacional da Propriedade Industrial.
      </p>
      <p>&nbsp;</p>
      <p>
        1.2 O software <strong>SNAKE</strong> &eacute; destinado a auxiliar os m&eacute;dicos e outros profissionais de sa&uacute;de a gerenciar as informa&ccedil;&otilde;es de seus pacientes, al&eacute;m de providenciar uma
        solu&ccedil;&atilde;o completa de gerenciamento de uma cl&iacute;nica m&eacute;dica, dando acesso a diversas ferramentas com linguagem de f&aacute;cil compreens&atilde;o. A Contratante ainda poder&aacute; acessar outros conte&uacute;dos
        de apoio, e utilizar diversos servi&ccedil;os oferecidos pela plataforma digital <strong>SNAKE</strong>, parceiros comerciais, al&eacute;m do gerenciamento do perfil da Contratante no software <strong>SNAKE</strong>. A plataforma
        digital <strong>SNAKE</strong>, por sua vez, possibilita somente a organiza&ccedil;&atilde;o das informa&ccedil;&otilde;es m&eacute;dicas dos pacientes da Contratante, sem intervir no contato, na inser&ccedil;&atilde;o de ditas
        informa&ccedil;&otilde;es, procedimentos, medicamentos ou posologias aplicadas, n&atilde;o sendo, nesta qualidade, fornecedor de quaisquer informa&ccedil;&otilde;es cadastradas pela Contratante no seu credenciamento na
        <strong>SNAKE</strong>.
      </p>
      <p>&nbsp;</p>
      <p>
        1.3 Tamb&eacute;m constitui objeto do contrato o fornecimento, pela Contratada &agrave; Contratante, da assist&ecirc;ncia permanente relativamente &agrave; opera&ccedil;&atilde;o do software, provendo solu&ccedil;&atilde;o &agrave;s
        consultas feitas pelos funcion&aacute;rios nomeados por esta, respons&aacute;veis pela administra&ccedil;&atilde;o do software, assessorando-os, tanto para a otimiza&ccedil;&atilde;o do uso dos recursos do mesmo, quanto para o
        esclarecimento de eventuais d&uacute;vidas, dentro do hor&aacute;rio comercial, ou seja, de 2&ordf; a 6&ordf; feira, de 09:00 &agrave;s 18:00 horas, hor&aacute;rio de Bras&iacute;lia/DF, atrav&eacute;s de atendimento online.
      </p>
      <p>&nbsp;</p>
      <p>
        1.4 A Contratante reconhece, expressamente, que as concess&otilde;es do direito de uso a que se refere a cl&aacute;usula 1.1 acima n&atilde;o configura, sob nenhuma hip&oacute;tese, a venda ou transfer&ecirc;ncia a si dos direitos
        legais do software, que, n&atilde;o obstante o presente licenciamento, permanecem sendo de &uacute;nica propriedade da Contratada, a quem coube exclusivamente a concep&ccedil;&atilde;o e desenvolvimento dos programas do software.
      </p>
      <p>&nbsp;</p>
      <p>
        1.5 A Contratante declara, expressamente, que conheceu todas as caracter&iacute;sticas operacionais do software customizado ora licenciado, nas pr&eacute;vias demonstra&ccedil;&otilde;es t&eacute;cnicas promovidas pela Contratada, e que
        tais caracter&iacute;sticas correspondem &agrave;s suas necessidades e aos seus procedimentos empresariais internos.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA SEGUNDA &ndash; PRAZO DE VIG&Ecirc;NCIA</strong></p>
      <p>&nbsp;</p>
      <p>
        2.1 O prazo de vig&ecirc;ncia deste contrato ser&aacute; de acordo com a op&ccedil;&atilde;o em que a Contratante escolher quando da sua ades&atilde;o &agrave; plataforma digital. A Contratante poder&aacute; aderir ao plano mensal,
        trimestral ou anual, podendo ser o mesmo renovado autom&aacute;tica e sucessivamente pelo mesmo per&iacute;odo, nas mesmas condi&ccedil;&otilde;es aqui determinadas.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA TERCEIRA &ndash; VALOR DO CONTRATO</strong></p>
      <p>&nbsp;</p>
      <p>
        3.1 A Contratante pagar&aacute; &agrave; Contratada o valor do plano escolhido quando da ades&atilde;o, qual seja mensal ou anual. Para habilita&ccedil;&atilde;o e utiliza&ccedil;&atilde;o da plataforma digital <strong>SNAKE</strong>, a
        Contratante dever&aacute; acessar o site www.snakeapp.com, escolher um dos Planos apresentados e se cadastrar, informando &agrave; <strong>SNAKE</strong> todos os dados exigidos, inclusive seu n&uacute;mero de registro no CPF, e
        responsabilizando-se a Contratante civil e criminalmente pela veracidade das informa&ccedil;&otilde;es, inclusive perante terceiros, obrigando-se tamb&eacute;m a manter seus dados atualizados. No ato do CADASTRO na Plataforma
        <strong>SNAKE</strong>, a Contratante dever&aacute; escolher entre os diversos planos, que ir&atilde;o compor a mensalidade. No entanto, caso a Contratante utilize alguns dos servi&ccedil;os adicionais dispon&iacute;veis na
        <strong>SNAKE</strong>, que n&atilde;o estejam j&aacute; inclu&iacute;dos na mensalidade do plano escolhido, este pagar&aacute; uma taxa adicional informada na hora do pedido para disponibiliza&ccedil;&atilde;o do novo servi&ccedil;o. A
        Contratante dever&aacute; ainda informar obrigatoriamente um endere&ccedil;o de e-mail v&aacute;lido de uso &uacute;nico e exclusivo. Neste sentido, a <strong>SNAKE</strong> n&atilde;o efetua qualquer checagem de
        informa&ccedil;&otilde;es ou de perfil de nenhum usu&aacute;rio que deseje utilizar o software ou os servi&ccedil;os disponibilizados na <strong>SNAKE</strong> no ato do credenciamento, nem realiza uma profunda
        investiga&ccedil;&atilde;o sobre a veracidade das informa&ccedil;&otilde;es apostas.
      </p>
      <p>&nbsp;</p>
      <p>3.2 A Contratada enviar&aacute; as Notas Fiscais de servi&ccedil;os em at&eacute; cinco dias ap&oacute;s o recebimento do dos valores pagos pela Contratante.</p>
      <p>&nbsp;</p>
      <p>
        3.3 Os valores relativos a este contrato ser&atilde;o anualmente reajustados com base na varia&ccedil;&atilde;o positiva do IGPM/FGV do per&iacute;odo ou, na falta deste, por qualquer outro &iacute;ndice que venha a substitu&iacute;-lo,
        tendo como data base a data de assinatura da Proposta Comercial. Os valores poder&atilde;o ser reajustados em periodicidade inferior a um ano, desde que haja altera&ccedil;&atilde;o na legisla&ccedil;&atilde;o vigente, mormente na Lei
        10.192/2001. Caso a varia&ccedil;&atilde;o do IGPM/FGV seja negativa, os valores n&atilde;o ser&atilde;o reajustados.
      </p>
      <p>&nbsp;</p>
      <p>
        3.4 Adicionalmente, os valores relativos a este contrato poder&atilde;o ser reajustados de pleno direito se, durante a vig&ecirc;ncia deste contrato, forem criados novos tributos ou modificadas as al&iacute;quotas dos tributos em
        vig&ecirc;ncia de modo a onerar a Contratada.
      </p>
      <p>&nbsp;</p>
      <p>
        3.5 O atraso no pagamento de qualquer parcela acarretar&aacute; a obriga&ccedil;&atilde;o de pagamento da Contratante &agrave; Contratada, al&eacute;m da parcela devida, de multa de 3,5% (dois por cento) e juros legais de mora de 1% ao
        m&ecirc;s, pro rata die, despesas banc&aacute;rias, mais corre&ccedil;&atilde;o monet&aacute;ria pela varia&ccedil;&atilde;o do IGPM/FGV do per&iacute;odo ou, na falta deste, por outro &iacute;ndice legal equivalente que venha a
        substitu&iacute;-lo.
      </p>
      <p>&nbsp;</p>
      <p>
        3.6 Ocorrendo atraso no pagamento de qualquer import&acirc;ncia ajustada no presente instrumento por prazo superior a 30 (trinta) dias consecutivos, os servi&ccedil;os contratados poder&atilde;o ser suspensos automaticamente e
        independentemente de aviso, at&eacute; que o pagamento do valor total inadimplente seja devidamente regularizado.
      </p>
      <p>&nbsp;</p>
      <p>
        3.7 O n&atilde;o pagamento dos valores aqui ajustados, depois de transcorridos 60 (sessenta) dias, consecutivos ou n&atilde;o, da data do respectivo vencimento, poder&aacute; implicar na rescis&atilde;o do presente contrato e/ou
        inclus&atilde;o do nome do Contratante nos cadastros de prote&ccedil;&atilde;o e restri&ccedil;&atilde;o ao cr&eacute;dito.
      </p>
      <p>&nbsp;</p>
      <p>
        3.8 Se, por qualquer motivo, em at&eacute; 05 (cinco) dias &uacute;teis antes da data de pagamento ajustada pelas partes, o Contratante n&atilde;o receber o boleto banc&aacute;rio ou fatura para pagamento, este dever&aacute; comunicar
        imediatamente &agrave; Contratada, que lhe informar&aacute; como efetuar o pagamento.
      </p>
      <p>&nbsp;</p>
      <p>3.9 A n&atilde;o cobran&ccedil;a de encargos n&atilde;o significar&aacute; nova&ccedil;&atilde;o ou perd&atilde;o por parte da Contratante, consistindo em ato de mera toler&acirc;ncia.</p>
      <p>&nbsp;</p>
      <p>
        3.10 Se, na defesa de seus direitos, ou para haver a satisfa&ccedil;&atilde;o do quanto lhe &eacute; devido, a Contratada tiver que recorrer a meios administrativos ou judiciais, ter&aacute; direito de receber, adicionalmente, 10% (dez
        por cento) da quantia devida, a t&iacute;tulo de despesas e encargos administrativos, sem preju&iacute;zo das demais penalidades e indeniza&ccedil;&otilde;es impostas por lei ou por este instrumento.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA QUARTA &ndash; CONDI&Ccedil;&Atilde;O DE FATURAMENTO E PAGAMENTO</strong></p>
      <p>&nbsp;</p>
      <p>4.1 Os pagamentos ser&atilde;o efetuados mensalmente pela Contratada &agrave; Contratante, pelo valor demonstrado na proposta comercial ou inserido na plataforma <strong>SNAKE</strong>.</p>
      <p>&nbsp;</p>
      <p>
        4.2 A responsabilidade pelo recolhimento dos tributos incidentes na opera&ccedil;&atilde;o objeto do presente contrato ser&aacute; determinada conforme a legisla&ccedil;&atilde;o tribut&aacute;ria vigente &agrave; &eacute;poca da
        ocorr&ecirc;ncia dos fatos geradores. Nas opera&ccedil;&otilde;es em que a Contratante for respons&aacute;vel pela reten&ccedil;&atilde;o dos tributos devidos, esta dever&aacute; ret&ecirc;-los e recolh&ecirc;-los na forma e no prazo
        definidos na legisla&ccedil;&atilde;o pr&oacute;pria, salvo quando a Contratada apresentar &agrave; Contratante certid&atilde;o ou documento atualizado comprovando que os tributos a serem retidos/recolhidos s&atilde;o objeto de
        discuss&atilde;o administrativa e/ou judicial e que est&atilde;o com a obrigatoriedade de seu recolhimento/reten&ccedil;&atilde;o suspensa ou afastada.
      </p>
      <p>&nbsp;</p>
      <p>
        4.3 Durante o prazo de vig&ecirc;ncia do presente contrato, se houver qualquer altera&ccedil;&atilde;o na legisla&ccedil;&atilde;o brasileira que venha a majorar ou diminuir os &ocirc;nus das Partes contratantes, os valores ora
        contratados ser&atilde;o revistos, a fim de adequ&aacute;-los &agrave;s modifica&ccedil;&otilde;es havidas, compensando-se qualquer diferen&ccedil;a decorrente dessas altera&ccedil;&otilde;es, tais como:
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          cria&ccedil;&atilde;o de novos tributos, taxas, contribui&ccedil;&otilde;es;ii. extin&ccedil;&atilde;o de tributos, taxas e contribui&ccedil;&otilde;es existentes;iii. altera&ccedil;&atilde;o de al&iacute;quotas e;iv.
          altera&ccedil;&atilde;o de bases de c&aacute;lculo.
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        4.4 Os pagamentos ser&atilde;o efetuados exclusivamente atrav&eacute;s de cr&eacute;dito em conta corrente mantida pela Contratada. Para a viabiliza&ccedil;&atilde;o dos mesmos a Contratada obriga-se a indicar &agrave; Contratante,
        mantendo-os atualizados, os seguintes dados banc&aacute;rios: Raz&atilde;o social/Nome do Favorecido, CNPJ/CPF, n&uacute;mero e nome do Banco, n&uacute;mero e nome da Ag&ecirc;ncia (inclusive d&iacute;gito verificador), endere&ccedil;o
        da Ag&ecirc;ncia (rua, bairro, cidade, estado e CEP), n&uacute;mero da Conta Corrente (inclusive d&iacute;gito verificador e o tipo de opera&ccedil;&atilde;o, quando for o caso).
      </p>
      <p>&nbsp;</p>
      <p>4.5 Em raz&atilde;o do meio de pagamento convencionado, &agrave; Contratada ser&aacute; facultada a emiss&atilde;o de duplicatas em decorr&ecirc;ncia dos faturamentos pelos Servi&ccedil;os prestados nos termos deste do Contrato.</p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA QUINTA &ndash; DAS OBRIGA&Ccedil;&Otilde;ES DA CONTRATADA</strong></p>
      <p>&nbsp;</p>
      <p>5.1 Constituem obriga&ccedil;&otilde;es da Contratada:</p>
      <p>&nbsp;</p>
      <ol>
        <li>
          garantir o correto funcionamento t&eacute;cnico do software, mantendo todas suas fun&ccedil;&otilde;es em plena operacionalidade e provendo as solu&ccedil;&otilde;es aos eventuais problemas t&eacute;cnicos reportados pela
          Contratante, salvo se tais problemas tiverem causa nas situa&ccedil;&otilde;es elencadas no par&aacute;grafo segundo da cl&aacute;usula oitava abaixo;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          manter, por si e por seus prepostos, completo sigilo sobre os dados, informa&ccedil;&otilde;es e pormenores fornecidos pela Contratante, na execu&ccedil;&atilde;o do presente Contrato, bem como n&atilde;o divulgar estes dados,
          informa&ccedil;&otilde;es e pormenores, relacionados com o objeto deste Contrato. Os dados poder&atilde;o ser compartilhados com terceiros caso a Contratante d&ecirc; consentimento livre, inequ&iacute;voco e informado para a
          Contratada; e,
        </li>
      </ol>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA SEXTA &ndash; DAS OBRIGA&Ccedil;&Otilde;ES DA CONTRATANTE</strong></p>
      <p>&nbsp;</p>
      <p>6.1 Constituem obriga&ccedil;&otilde;es da Contratante:</p>
      <p>&nbsp;</p>
      <ol>
        <li>
          efetuar todos os pagamentos descritos nas cl&aacute;usulas terceira e quarta deste Contrato, nas respectivas datas dos seus vencimentos, sob pena de, n&atilde;o o fazendo, por sua culpa exclusiva, e desde que o pagamento n&atilde;o
          esteja suspenso por comprovada falha t&eacute;cnica de funcionamento do sistema, ap&oacute;s 30 (trinta) dias, permitir &agrave; Contratada, concomitante ou alternativamente:
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>i.i) tomar provid&ecirc;ncias legais, tais como o protesto do t&iacute;tulo vencido, independentemente de aceite, e promover os procedimentos judiciais cab&iacute;veis;</p>
      <p>&nbsp;</p>
      <p>i.ii) bloquear acesso ao software por qualquer meio; e,</p>
      <p>&nbsp;</p>
      <p>i.iii) considerar rescindido, de pleno direito, o presente Contrato, sem que caiba qualquer tipo de indeniza&ccedil;&atilde;o ou obriga&ccedil;&atilde;o &agrave; Contratada;</p>
      <p>&nbsp;</p>
      <p>i.iv) manter a supervis&atilde;o, administra&ccedil;&atilde;o e controle do uso do software, designando pessoal capacitado para a opera&ccedil;&atilde;o adequada do mesmo;</p>
      <p>&nbsp;</p>
      <ol>
        <li>manter a supervis&atilde;o, administra&ccedil;&atilde;o e controle do uso do software, designando pessoal capacitado e habilitado para a opera&ccedil;&atilde;o adequada do mesmo;</li>
      </ol>
      <p>&nbsp;</p>
      <p>iii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;configurar e manter configurados, os seus equipamentos de processamento de dados, nos quais o software ser&aacute; utilizado;</p>
      <p>&nbsp;</p>
      <ol>
        <li>
          n&atilde;o introduzir, por seus empregados, prepostos ou terceiros, sob qualquer forma, qualquer modifica&ccedil;&atilde;o no software, em raz&atilde;o de suas necessidades ou n&atilde;o, sob pena de desobrigar a Contratada da
          qualidade e da garantia de funcionamento do sistema, al&eacute;m de sujeitar-se &agrave;s san&ccedil;&otilde;es legais;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          tomar todas as medidas de seguran&ccedil;a, perante os seus empregados e terceiros com os quais mantenha rela&ccedil;&otilde;es comerciais, para que n&atilde;o sejam violados quaisquer direitos sobre o software objeto deste
          contrato;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          utilizar o software somente no ambiente t&eacute;cnico de opera&ccedil;&atilde;o especificado no site www.snakeapp.com. A sua utiliza&ccedil;&atilde;o em outra plataforma ou ambiente operacional dever&aacute; ser objeto de
          aditamento contratual;
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        vii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;n&atilde;o utilizar as especifica&ccedil;&otilde;es do software &ndash; e n&atilde;o permitir que terceiros ligados a si as utilizem &ndash; com a finalidade de criar outro sistema com
        a mesma destina&ccedil;&atilde;o, sob pena de incorrer nas san&ccedil;&otilde;es previstas tanto neste contrato quanto na legisla&ccedil;&atilde;o vigente, mormente &agrave;s multas pecuni&aacute;rias e tipos penais previstas nas Leis
        9.609/98 e 9.610/98;
      </p>
      <p>&nbsp;</p>
      <p>
        viii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;adquirir previamente, inteiramente &agrave;s suas expensas, as licen&ccedil;as de uso originais dos softwares de terceiros atualmente necess&aacute;rios e fundamentais para a
        instala&ccedil;&atilde;o, implanta&ccedil;&atilde;o e opera&ccedil;&atilde;o adequadas do software objeto do presente contrato;
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          efetuar todos os atos necess&aacute;rios a confer&ecirc;ncia de dados, evitando o que acredita ser fonte de erro ou de dano, respondendo pela omiss&atilde;o, in&eacute;rcia e imprud&ecirc;ncia, cabendo&ndash;lhe a responsabilidade
          pela gest&atilde;o administrativa, econ&ocirc;mica, e fiscal do seu neg&oacute;cio;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          emitir e/ou imprimir os documentos legais, fiscais ou outras obriga&ccedil;&otilde;es geradas pelo software quando for o caso em tempo h&aacute;bil de envi&aacute;&ndash;los aos &oacute;rg&atilde;os a que se destinam dentro do prazo
          determinado. O n&atilde;o cumprimento destas obriga&ccedil;&otilde;es, n&atilde;o acarretar&aacute;, em hip&oacute;tese alguma em responsabilidade para a Contratada;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          enviar por escrito para a Contratada, a descri&ccedil;&atilde;o dos v&iacute;cios, bugs (imperfei&ccedil;&otilde;es na implementa&ccedil;&atilde;o das fun&ccedil;&otilde;es para as quais o software foi projetado), problemas e
          pend&ecirc;ncias havidos com o software;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <li>
          A descri&ccedil;&atilde;o dever&aacute; ser realizada de forma detalhada, incluindo documenta&ccedil;&atilde;o, relat&oacute;rios de erros e demais informa&ccedil;&otilde;es que relatem as circunst&acirc;ncias em que o problema
          ocorreu, sob pena de impossibilitar a Contratada de solucionar o(s) problema(s) ou erro(s);
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>Reportar por escrito, no prazo m&aacute;ximo de 72 (setenta e duas horas), &agrave; Contratada a ocorr&ecirc;ncia de qualquer defeito no software, sob pena de n&atilde;o ser devida qualquer repara&ccedil;&atilde;o.</li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Ap&oacute;s a an&aacute;lise do caso reportado, se n&atilde;o for constatado erro ou falha da Contratada, os disp&ecirc;ndios temporais apurados na resolu&ccedil;&atilde;o dos problemas ser&atilde;o custeados pelo Contratante,
          mediante apresenta&ccedil;&atilde;o de formul&aacute;rios de servi&ccedil;o espec&iacute;ficos por parte da Contratada;
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        xii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;garantir as configura&ccedil;&otilde;es t&eacute;cnicas m&iacute;nimas que viabilizam o bom funcionamento do software, evoluir com seu equipamento, banco de dados, infraestrutura e
        ambiente operacional, de forma a garantir o desempenho de processamento mantendo-se compat&iacute;vel com as evolu&ccedil;&otilde;es decorrentes da tecnologia ou com as novas atualiza&ccedil;&otilde;es do software da Contratada a serem
        oferecidas, sob pena de impossibilidade de manuten&ccedil;&atilde;o do contrato, desobrigando-se a Contratada das condi&ccedil;&otilde;es que lhe s&atilde;o impostas por este instrumento;
      </p>
      <p>&nbsp;</p>
      <p>
        xiii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;comunicar imediatamente a Contratada as altera&ccedil;&otilde;es cadastrais que porventura ocorrerem durante a vig&ecirc;ncia deste contrato, tais como Raz&atilde;o Social, CNPJ,
        endere&ccedil;o, troca do propriet&aacute;rio, transforma&ccedil;&atilde;o do tipo societ&aacute;rio, ou quaisquer outras altera&ccedil;&otilde;es, para que a Contratada possa tomar as provid&ecirc;ncias internas que se fizerem
        necess&aacute;rias.
      </p>
      <p>&nbsp;</p>
      <p>
        xiv.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cumprir as legisla&ccedil;&otilde;es trabalhistas, fiscais e tribut&aacute;rias, aplic&aacute;veis ao seu neg&oacute;cio, sendo vedada a utiliza&ccedil;&atilde;o do software para fins
        os quais n&atilde;o sejam os contratados ou em desacordo com a legisla&ccedil;&atilde;o vigente;
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>responsabilizar&ndash;se por qualquer infra&ccedil;&atilde;o legal, nos &acirc;mbitos civil, penal, autoral e todos os demais, que, eventualmente, decorram da utiliza&ccedil;&atilde;o do software licenciado.</li>
      </ol>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA S&Eacute;TIMA &ndash; DA INTRANSFERIBILIDADE DO SOFTWARE E UTILIZA&Ccedil;&Atilde;O DAS SENHAS</strong></p>
      <p>&nbsp;</p>
      <p>
        7.1 O software, objeto do presente licenciamento de uso, &eacute; intransfer&iacute;vel pela Contratante a quem quer que seja, sob qualquer hip&oacute;tese, sem a autoriza&ccedil;&atilde;o pr&eacute;via, expressa e espec&iacute;fica da
        Contratada.
      </p>
      <p>&nbsp;</p>
      <p>
        7.2 A comprova&ccedil;&atilde;o, seja por not&iacute;cia fidedigna de terceiros, por observa&ccedil;&atilde;o direta in loco ou, ainda, por detec&ccedil;&atilde;o remota indicada por mecanismos de seguran&ccedil;a internos do sistema,
        da exist&ecirc;ncia de outra(s) c&oacute;pia(s) do sistema, instalada(s) sem o expresso e formal consentimento da Contratada, em quaisquer depend&ecirc;ncias, postos de atendimento ou em terceiros ligados &agrave; Contratante que
        n&atilde;o o(s) local(is) especificado(s) na Proposta Comercial, configurar&aacute; situa&ccedil;&atilde;o de uso de c&oacute;pia n&atilde;o autorizada, com viola&ccedil;&atilde;o aos direitos de propriedade, sujeitando-se a Contratante
        &agrave;s penalidades previstas no presente Contrato e na legisla&ccedil;&atilde;o em vigor.
      </p>
      <p>&nbsp;</p>
      <p>
        7.3 A senha de administra&ccedil;&atilde;o possibilita o acesso ao servidor para o seu gerenciamento, administra&ccedil;&atilde;o e programa&ccedil;&atilde;o. A senha de administra&ccedil;&atilde;o ser&aacute; cadastrada e/ou enviada
        &agrave; Contratante. A senha &eacute; pessoal e intransfer&iacute;vel, sendo que a Contratante &eacute; a respons&aacute;vel por sua administra&ccedil;&atilde;o.
      </p>
      <p>&nbsp;</p>
      <p>
        7.4 Sempre que a Contratante solicitar da Contratada a informa&ccedil;&atilde;o da senha de administra&ccedil;&atilde;o por ele cadastrada e/ou alterada, dever&aacute; a Contratante efetuar tal pedido via telefone ou e-mail. Neste caso
        a <strong>SNAKE</strong> fica autorizada a ignorar a senha anteriormente cadastrada pela Contratante e enviar uma nova senha ao e-mail principal fornecido pela Contratante.
      </p>
      <p>&nbsp;</p>
      <p>7.5 Apenas o endere&ccedil;o eletr&ocirc;nico de &ldquo;e-mail&rdquo; cadastrado pela Contratante receber&aacute; a senha de administra&ccedil;&atilde;o e suas eventuais substitui&ccedil;&otilde;es e altera&ccedil;&otilde;es.</p>
      <p>&nbsp;</p>
      <p>
        7.6 Em caso de pedido de substitui&ccedil;&atilde;o do endere&ccedil;o eletr&ocirc;nico de e-mail para envio de senha, a <strong>SNAKE</strong> apenas o atender&aacute; mediante a apresenta&ccedil;&atilde;o, pelo solicitante, dos
        documentos que comprovem sua legitimidade para efetiva&ccedil;&atilde;o da solicita&ccedil;&atilde;o. Ap&oacute;s a apresenta&ccedil;&atilde;o de documentos h&aacute;beis, a Contratada enviar&aacute; a nova senha para o endere&ccedil;o
        eletr&ocirc;nico de &ldquo;e-mail&rdquo; indicado na solicita&ccedil;&atilde;o de substitui&ccedil;&atilde;o.
      </p>
      <p>&nbsp;</p>
      <p>7.7 &Eacute; de exclusiva responsabilidade do receptor da senha a defini&ccedil;&atilde;o da pol&iacute;tica de privacidade na utiliza&ccedil;&atilde;o da mesma.</p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA OITAVA &ndash;&nbsp;&nbsp;DA COMERCIALIZA&Ccedil;&Atilde;O DO SISTEMA PELA CONTRATADA PARA TERCEIROS</strong></p>
      <p>&nbsp;</p>
      <p>
        8.1 As partes declaram estar cientes de que Contratada &eacute; a &uacute;nica detentora dos direitos autorais do sistema, estando, como efetivamente est&aacute;, livre e desembara&ccedil;ada para comercializ&aacute;-lo com terceiros,
        por si pr&oacute;pria ou por qualquer outra entidade por ela credenciada, sob as modalidades de concess&atilde;o da licen&ccedil;a do direito de uso ou venda integral ou parcial dos programas-fontes do sistema.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA NONA &ndash; DA RESCIS&Atilde;O</strong></p>
      <p>&nbsp;</p>
      <p>9.1 O presente Contrato poder&aacute; ser rescindido nas seguintes circunst&acirc;ncias:</p>
      <p>&nbsp;</p>
      <p>9.1.1 T&eacute;rmino do prazo, iniciado a partir da data em que o software da Contratada for baixado pelo Contratante;</p>
      <p>&nbsp;</p>
      <p>
        9.1.2 Envio de um termo por escrito e assinado por representante legal da parte denunciante, o qual dever&aacute; ser remetido com anteced&ecirc;ncia m&iacute;nima de 60 (sessenta) dias para a parte contr&aacute;ria, mediante carta
        registrada, com c&oacute;pia por e&ndash;mail para o endere&ccedil;o eletr&ocirc;nico da mesma.
      </p>
      <p>&nbsp;</p>
      <p>
        9.2 Ocorrendo quaisquer das hip&oacute;teses adiante elencadas, ser&aacute; facultado a parte contr&aacute;ria o direito de rescindir de pleno direito o presente instrumento, a qualquer tempo e independentemente de formalidade judicial
        ou extrajudicial:
      </p>
      <p>&nbsp;</p>
      <p>9.2.1 Infra&ccedil;&atilde;o a quaisquer cl&aacute;usulas ou condi&ccedil;&otilde;es aqui pactuadas;</p>
      <p>&nbsp;</p>
      <p>9.2.2 Viola&ccedil;&atilde;o aos Direitos Autorais;</p>
      <p>&nbsp;</p>
      <p>9.2.3 Atraso no pagamento superior a 60 (sessenta) dias, consecutivos ou n&atilde;o.</p>
      <p>&nbsp;</p>
      <p>
        9.2.4 Caso uma das partes seja submetida a procedimento de recupera&ccedil;&atilde;o judicial ou extrajudicial, fal&ecirc;ncia, recupera&ccedil;&atilde;o da empresa, interven&ccedil;&atilde;o, liquida&ccedil;&atilde;o ou
        dissolu&ccedil;&atilde;o da sociedade, bem como a configura&ccedil;&atilde;o de situa&ccedil;&atilde;o pr&eacute;-falimentar ou de pr&eacute;&ndash;insolv&ecirc;ncia, inclusive com t&iacute;tulos vencidos e protestados ou
        a&ccedil;&otilde;es de execu&ccedil;&atilde;o que comprometam a solidez financeira da empresa;
      </p>
      <p>&nbsp;</p>
      <p>9.2.5 Ajuizamento de qualquer a&ccedil;&atilde;o, de uma parte contra a outra;</p>
      <p>&nbsp;</p>
      <p>9.2.6 Por motivo de for&ccedil;a maior ou caso fortuito, devidamente comprovados, na forma disposta no artigo 393 do C&oacute;digo Civil Brasileiro, os quais impe&ccedil;am a execu&ccedil;&atilde;o dos termos ora pactuados.</p>
      <p>&nbsp;</p>
      <p>
        9.3 Verificada a rescis&atilde;o ou resili&ccedil;&atilde;o do contrato, fica o Contratante obrigado a efetuar a quita&ccedil;&atilde;o de todos os pagamentos e obriga&ccedil;&otilde;es pendentes at&eacute; este momento, sem
        preju&iacute;zo das demais penalidades e indeniza&ccedil;&otilde;es previstas em lei e neste instrumento.
      </p>
      <p>&nbsp;</p>
      <p>
        9.4 A rescis&atilde;o contratual dever&aacute; ser formalizada, mediante assinatura de ambas as partes, atrav&eacute;s de distrato, a ser elaborado pela Contratada, ap&oacute;s o que ser&aacute; considerado para todos os efeitos a
        extin&ccedil;&atilde;o da rela&ccedil;&atilde;o contratual, na forma do art. 472 do C&oacute;digo Civil.
      </p>
      <p>&nbsp;</p>
      <p>
        9.4.1 O Distrato formalizar&aacute; o fim das obriga&ccedil;&otilde;es, a quita&ccedil;&atilde;o de qualquer pend&ecirc;ncia entre as partes e a garantia de que n&atilde;o caber&aacute; indeniza&ccedil;&atilde;o ou &ocirc;nus de
        qualquer natureza por nenhuma das partes;
      </p>
      <p>&nbsp;</p>
      <p>9.4.2 A assinatura do Distrato somente ser&aacute; realizada ap&oacute;s a quita&ccedil;&atilde;o de todas as pend&ecirc;ncias financeiras do Contratante com a Contratada.</p>
      <p>&nbsp;</p>
      <p>9.5 A rescis&atilde;o ou resili&ccedil;&atilde;o do presente contrato por qualquer modo, acarretar&aacute;:</p>
      <p>&nbsp;</p>
      <p>9.5.1 A paralisa&ccedil;&atilde;o imediata dos servi&ccedil;os que estavam sendo executados;</p>
      <p>&nbsp;</p>
      <p>9.5.2 A obriga&ccedil;&atilde;o do Contratante &agrave; quita&ccedil;&atilde;o de qualquer saldo devedor, devidamente atualizado pelo IGPM/FGV, acrescido de multa morat&oacute;ria e juros legais;</p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA D&Eacute;CIMA &ndash; QUEST&Otilde;ES TRABALHISTAS E PROCESSUAIS</strong></p>
      <p>&nbsp;</p>
      <p>
        10.1 O presente contrato &eacute; de natureza estritamente civil, n&atilde;o se estabelecendo qualquer v&iacute;nculo empregat&iacute;cio ou responsabilidade da Contratada em rela&ccedil;&atilde;o &agrave; Contratante sobre o pessoal
        que estas venham a empregar na execu&ccedil;&atilde;o dos servi&ccedil;os ora contratados, correndo por conta de cada parte as despesas e encargos trabalhistas, sociais, acident&aacute;rios e demais reflexos decorrentes da
        contrata&ccedil;&atilde;o da m&atilde;o-de&ndash;obra destinada &agrave; presta&ccedil;&atilde;o dos servi&ccedil;os na medida da sua responsabilidade.
      </p>
      <p>&nbsp;</p>
      <p>
        10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Contratante dever&aacute; comunicar, por e-mail ou telefone, &agrave; Contratada, acerca de todas as cita&ccedil;&otilde;es, intima&ccedil;&otilde;es e notifica&ccedil;&otilde;es que lhe forem
        entregues, caso endere&ccedil;adas &agrave; Contratada, no prazo m&aacute;ximo de 01 (um) dias &uacute;til contados da data do recebimento.
      </p>
      <p>&nbsp;</p>
      <p>
        10.3 A Contratante se obriga a defender e manter inc&oacute;lume a Contratada de todo processo e/ou a&ccedil;&otilde;es judiciais ou administrativas, quaisquer reivindica&ccedil;&otilde;es de seus empregados, reclama&ccedil;&otilde;es
        trabalhistas em geral e demandas de terceiros (inclusive e especialmente les&otilde;es pessoais, morte, perda patrimonial, perdas e danos em geral, multas, honor&aacute;rios advocat&iacute;cios e custas processuais) decorrentes, direta
        ou indiretamente, (i) da rela&ccedil;&atilde;o de emprego por ela mantida, sendo neste particular, em quaisquer circunst&acirc;ncias, considerada como &uacute;nica e exclusiva empregadora, ou (ii) de a&ccedil;&atilde;o, omiss&atilde;o,
        culpa ou dolo da Contratante, de seus empregados, prepostos e de suas subcontratadas, responsabilizando-se em ambos os casos, pelo ressarcimento, devidamente atualizado, de eventuais condena&ccedil;&otilde;es e quaisquer despesas que
        vierem a ser imputadas, administrativa ou judicialmente, &agrave; Contratante, a que t&iacute;tulo for, decorrentes do Contrato.
      </p>
      <p>&nbsp;</p>
      <p>
        10.4 Caso a Contratada seja autuada, notificada, citada, intimada ou condenada em raz&atilde;o do n&atilde;o cumprimento, em &eacute;poca pr&oacute;pria, de qualquer obriga&ccedil;&atilde;o atribu&iacute;vel &agrave; Contratante, seja
        de natureza fiscal, trabalhista, previdenci&aacute;ria ou de qualquer outra esp&eacute;cie, assistir-lhe-&aacute; o direito de reter, a partir do recebimento da autua&ccedil;&atilde;o, notifica&ccedil;&atilde;o, cita&ccedil;&atilde;o ou
        da intima&ccedil;&atilde;o, a quantia referente &agrave; conting&ecirc;ncia calculada de acordo com os par&acirc;metros estabelecidos pela Contratada. Este valor ser&aacute; restitu&iacute;do &agrave; Contratante nos casos em que a
        mesma satisfizer a respectiva obriga&ccedil;&atilde;o ou quando a Contratada for exclu&iacute;da do polo passivo da demanda, mediante decis&atilde;o irrecorr&iacute;vel.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA D&Eacute;CIMA PRIMEIRA &ndash;DA LIMITA&Ccedil;&Atilde;O DE RESPONSABILIDADE</strong></p>
      <p>&nbsp;</p>
      <p>
        11.1 A Contratada n&atilde;o ser&aacute; respons&aacute;vel por quaisquer danos diretos ou indiretos, incidentais ou consequentes, ou relativos a lucros cessantes, despesas, taxas, multas e demais encargos fiscais ou trabalhistas, perda
        de receitas, de dados, ou de uso de dados, incorridos com a utiliza&ccedil;&atilde;o ou com a impossibilidade de utiliza&ccedil;&atilde;o do software ou com os resultados produzidos por este mesmo que provocado por defeito no software,
        pelo cliente ou por quaisquer terceiros, seja por a&ccedil;&atilde;o baseada em contrato ou por ato il&iacute;cito, mesmo que a Contratada ou qualquer outra pessoa tenha sido advertida da possibilidade da ocorr&ecirc;ncia de tais danos,
        ainda que o preju&iacute;zo decorra de alguma falha nas funcionalidades do software seja resultante de sua m&aacute; ou incorreta utiliza&ccedil;&atilde;o. Caso seja realmente comprovado que a Contratada incorreu em dano direto &agrave;
        Contratante esses n&atilde;o poder&atilde;o ultrapassar &agrave; m&eacute;dia das &uacute;ltimas tr&ecirc;s faturas pagas pela Contratante &agrave; Contratada.
      </p>
      <p>&nbsp;</p>
      <p>
        11.2 A Contratada n&atilde;o se responsabiliza por danos causados a equipamentos, outros programas de computador, redes, terceiros de forma direta ou indireta, ou outro dano qualquer, provocados por qualquer situa&ccedil;&atilde;o como
        mas n&atilde;o se limitando a estas, falha de opera&ccedil;&atilde;o, falhas de energia el&eacute;trica, ar condicionado, elementos radioativos ou eletrost&aacute;ticos, poluentes ou outros assemelhados,&nbsp;outros programas de
        computador, licenciados ou n&atilde;o, previamente ou posteriormente instalados, tais como outros aplicativos, bancos de dados, sistema operacional e bibliotecas, ou algum tipo de programa externo, ou ainda aqueles vulgarmente
        conhecidos como v&iacute;rus de inform&aacute;tica, ou qualquer outra causa.
      </p>
      <p>&nbsp;</p>
      <p>
        11.3 A Contratada n&atilde;o ser&aacute; respons&aacute;vel por impostos, taxas e contribui&ccedil;&otilde;es ou compromissos de qualquer esp&eacute;cie gerados para o cliente, mesmo que estes sejam produzidos pelo software, ainda que
        tenham sido gerados com erro ou em desacordo com normas ou leis, ou que tenham sido obtidos com base em informa&ccedil;&otilde;es produzidas direta ou indiretamente pelo software, ou ainda que tenham qualquer tipo de
        liga&ccedil;&atilde;o com o servi&ccedil;o objeto deste contrato.
      </p>
      <p>&nbsp;</p>
      <p>
        11.4 Caso o Contratante tenha interesse em garantir algum n&iacute;vel de servi&ccedil;o, poder&aacute; ser contratado servi&ccedil;o espec&iacute;fico, atrav&eacute;s da assinatura de um contrato pr&oacute;prio para este fim (SLA),
        definindo a m&eacute;trica em si, a forma de coleta dos indicadores da m&eacute;trica (SLM &ndash; Service Level Management), o limite para a m&eacute;trica (N&iacute;vel do Servi&ccedil;o) e o valor para esta presta&ccedil;&atilde;o de
        servi&ccedil;o adicional.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA D&Eacute;CIMA SEGUNDA &ndash; DA RESPONSABILIDADE SOCIAL</strong></p>
      <p>&nbsp;</p>
      <p>12.1 Das Obriga&ccedil;&otilde;es para n&atilde;o ado&ccedil;&atilde;o de pr&aacute;ticas de trabalho ilegal:</p>
      <p>&nbsp;</p>
      <p>12.1.1 A Contratada se compromete a n&atilde;o adotar pr&aacute;ticas de trabalho an&aacute;logo ao escravo e trabalho ilegal de crian&ccedil;as e adolescentes no cumprimento do presente Contrato.</p>
      <p>&nbsp;</p>
      <p>
        12.1.2 A Contratada se compromete a n&atilde;o empregar trabalhadores menores de 16 (dezesseis) anos de idade, salvo na condi&ccedil;&atilde;o de aprendiz a partir de 14 (quatorze) anos de idade, nos termos da Lei n&ordm; 10.097, de
        19.12.2000, e da Consolida&ccedil;&atilde;o das Leis do Trabalho.
      </p>
      <p>&nbsp;</p>
      <p>
        12.1.3 A Contratada se compromete a n&atilde;o empregar adolescentes at&eacute; 18 (dezoito) anos de idade, em locais prejudiciais &agrave; sua forma&ccedil;&atilde;o, ao seu desenvolvimento f&iacute;sico, ps&iacute;quico, moral e
        social, bem como, em locais e servi&ccedil;os perigosos ou insalubres, em hor&aacute;rios que n&atilde;o permitam a frequ&ecirc;ncia &agrave; escola e, ainda, em hor&aacute;rio noturno, considerado este o per&iacute;odo compreendido
        entre as 22:00 hs &agrave;s 05:00 hs.
      </p>
      <p>&nbsp;</p>
      <p>
        12.2 A Contratada se compromete a proteger e preservar o meio ambiente, bem como a prevenir contra pr&aacute;ticas danosas ao meio ambiente, executando seus servi&ccedil;os em observ&acirc;ncia dos atos legais, normativos e
        administrativos relativos &agrave; &aacute;rea de meio ambiente e correlatas, emanadas das esferas Federal, Estaduais e Municipais, incluindo, mas n&atilde;o limitando ao cumprimento da Lei Federal n&ordm; 6.938/81 (Pol&iacute;tica
        Nacional do Meio Ambiente) e da Lei n&ordm; 9.605/98 (Lei dos Crimes Ambientais), implementando ainda esfor&ccedil;os nesse sentido junto aos seus respectivos fornecedores de produtos e servi&ccedil;os, a fim de que esses tamb&eacute;m
        se comprometam a conjugar esfor&ccedil;os para proteger e preservar o meio ambiente, bem como a prevenir contra pr&aacute;ticas danosas ao meio ambiente, em suas respectivas rela&ccedil;&otilde;es comerciais.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA D&Eacute;CIMA TERCEIRA &ndash; CONFIDENCIALIDADE</strong></p>
      <p>&nbsp;</p>
      <p>
        13.1 &Eacute; vedada a divulga&ccedil;&atilde;o pelas Partes, a qualquer tempo e sob qualquer forma ou natureza, de dados e informa&ccedil;&otilde;es confidenciais obtidos em virtude do Contrato (&ldquo;Informa&ccedil;&atilde;o
        Confidencial&rdquo;), salvo se com o pr&eacute;vio e expresso consentimento da outra Parte.
      </p>
      <p>&nbsp;</p>
      <p>13.1.1 Obtido o pr&eacute;vio e expresso consentimento, a Parte que estiver promovendo a divulga&ccedil;&atilde;o de Informa&ccedil;&atilde;o Confidencial, far&aacute; expressa men&ccedil;&atilde;o &agrave; sua origem.</p>
      <p>&nbsp;</p>
      <p>
        13.1.2 As Partes entendem que Informa&ccedil;&atilde;o Confidencial &eacute; toda e qualquer informa&ccedil;&atilde;o, escrita ou falada, que:(i) diga respeito a ideias, conceitos, pesquisa, desenvolvimento, atividades comerciais,
        proposta(s) t&eacute;cnica(s) e/ou comercial (ais), produtos, servi&ccedil;os e conhecimento t&eacute;cnico, atuais ou futuros, a serem desenvolvidos;(ii) tenha sido revelada por uma Parte a outra Parte, antes, durante ou ap&oacute;s a
        assinatura do Contrato; e(iii) seja c&oacute;pia, aut&ecirc;ntica ou n&atilde;o, dos itens anteriormente indicados.
      </p>
      <p>&nbsp;</p>
      <p>
        13.1.3 As Partes declaram e concordam que o t&eacute;rmino do Contrato, por qualquer raz&atilde;o, implica na devolu&ccedil;&atilde;o de toda e qualquer documenta&ccedil;&atilde;o relativa &agrave; Informa&ccedil;&atilde;o Confidencial.
      </p>
      <p>&nbsp;</p>
      <p>
        13.2 As Partes, entretanto, poder&atilde;o divulgar internamente, em todos os momentos, a exist&ecirc;ncia e os termos e condi&ccedil;&otilde;es do Contrato aos seus consultores, subcontratados ou outras pessoas que estejam envolvidas
        profissionalmente com a Parte reveladora, desde que todos estes antes indicados tamb&eacute;m se comprometam contratualmente com as mesmas obriga&ccedil;&otilde;es de confidencialidade e sujeitas &agrave;s penalidades c&iacute;veis e
        criminais.
      </p>
      <p>&nbsp;</p>
      <p>
        13.3 As partes declaram que est&atilde;o cientes das normas que regem os prontu&aacute;rios m&eacute;dicos, incluindo mas n&atilde;o se limitando a Resolu&ccedil;&atilde;o CFM n&ordm; 1.605/2000 e Resolu&ccedil;&atilde;o CFM n&ordm;
        1.638/2002 e Resolu&ccedil;&atilde;o CFM n&ordm; 1.821/2007 com a altera&ccedil;&atilde;o que lhe foi imposta pela Resolu&ccedil;&atilde;o CFM n&ordm; 2218/18.
      </p>
      <p>&nbsp;</p>
      <p>
        13.3 As estipula&ccedil;&otilde;es e obriga&ccedil;&otilde;es constantes da presente cl&aacute;usula n&atilde;o ser&atilde;o aplicadas a nenhuma informa&ccedil;&atilde;o que: (i) seja de dom&iacute;nio p&uacute;blico; (ii) j&aacute;
        esteja em poder da Parte receptora como resultado de sua pr&oacute;pria pesquisa ou desenvolvimento;&nbsp;(iii) tenha sido legitimamente recebida de terceiros; (iv) seja revelada em raz&atilde;o de uma ordem v&aacute;lida,
        administrativa ou judicial, somente at&eacute; a extens&atilde;o de tais ordens, contanto que a Parte receptora tenha notificado a exist&ecirc;ncia de tal ordem, previamente e por escrito, &agrave; Parte reveladora, dando a esta, na
        medida do poss&iacute;vel, tempo h&aacute;bil para pleitear medidas de prote&ccedil;&atilde;o que julgar cab&iacute;veis.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA D&Eacute;CIMA QUARTA &ndash; DA GARANTIA DOS SERVI&Ccedil;OS</strong></p>
      <p>&nbsp;</p>
      <p>
        14.1 A Contratada garante que os Servi&ccedil;os ser&atilde;o realizados de acordo com as normas t&eacute;cnicas, com os materiais apropriados e com uso de m&atilde;o-de-obra especializada, como exigido, e que estar&atilde;o isentos de
        defeitos que possam colocar em risco seu uso, seguran&ccedil;a e prop&oacute;sito.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA DECIMA QUINTA &ndash; FRAUDE E CORRUP&Ccedil;&Atilde;O</strong></p>
      <p>&nbsp;</p>
      <p>
        15.1 As partes dever&atilde;o tomar todas as medidas necess&aacute;rias, de acordo com as boas pr&aacute;ticas comerciais, observando plenamente todas as leis anticorrup&ccedil;&atilde;o aplic&aacute;veis, mormente a Lei 12.846/13, para
        impedir qualquer atividade fraudulenta por si (inclusive por seus acionistas, conselheiros, diretores e empregados) e/ou por quaisquer fornecedores, agentes, ou empregados dessas com rela&ccedil;&atilde;o ao recebimento de quaisquer
        recursos de uma parte a outra. A parte dever&aacute; notificar imediatamente a outra se tiver motivo para suspeitar que qualquer fraude tenha ocorrido, esteja ocorrendo, ou provavelmente ocorrer&aacute;.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA D&Eacute;CIMA SEXTA &ndash; FOR&Ccedil;A MAIOR OU CASO FORTUITO</strong></p>
      <p>&nbsp;</p>
      <p>
        16.1 Nenhuma das Partes deve ser respons&aacute;vel pelo atraso ou pelo n&atilde;o cumprimento das obriga&ccedil;&otilde;es contidas neste Contrato, no todo ou em parte, em decorr&ecirc;ncia, de for&ccedil;a maior ou caso fortuito, nos
        termos do Artigo 393 do C&oacute;digo Civil Brasileiro.
      </p>
      <p>&nbsp;</p>
      <p>
        16.2 Em caso de falta de cumprimento total ou parcial de qualquer obriga&ccedil;&atilde;o por qualquer Parte e em virtude de um caso de for&ccedil;a maior e/ou caso fortuito, esta Parte ficar&aacute; isenta de penalidades pelo
        n&atilde;o cumprimento das obriga&ccedil;&otilde;es enquanto perdurar sua impossibilidade de cumprimento.
      </p>
      <p>&nbsp;</p>
      <p>
        16.3 Nenhuma das Partes poder&aacute; exigir da outra que fa&ccedil;a concess&otilde;es, ou que aceite qualquer tipo de reivindica&ccedil;&atilde;o, ou que ponha fim a qualquer greve ou qualquer outro tipo de a&ccedil;&atilde;o
        organizada de trabalhadores que configurem evento de for&ccedil;a maior ou caso fortuito.
      </p>
      <p>&nbsp;</p>
      <p>
        16.4 A Parte afetada por qualquer evento de for&ccedil;a maior ou caso fortuito comunicar&aacute; o fato &agrave; outra Parte imediatamente e, no menor prazo poss&iacute;vel, esclarecer&aacute; as circunst&acirc;ncias, as
        a&ccedil;&otilde;es em curso para amenizar as perdas e solucionar o ocorrido, o tempo estimado de dura&ccedil;&atilde;o e tudo o mais que for necess&aacute;rio &agrave; compreens&atilde;o do fato, suas consequ&ecirc;ncias e
        solu&ccedil;&atilde;o.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA D&Eacute;CIMA S&Eacute;TIMA &ndash; DOS DIREITOS AUTORAIS DO SOFTWARE</strong></p>
      <p>&nbsp;</p>
      <p>17.1 &ndash; &Eacute; expressamente vedado ao Contratante ou empresa do mesmo grupo societ&aacute;rio ou coligada, na pessoa de seus representantes, prepostos, empregados, gerentes, procuradores, sucessores ou terceiros interessados:</p>
      <p>&nbsp;</p>
      <p>
        17.1.1 &ndash; Copiar, alterar, sublicenciar, vender, dar em loca&ccedil;&atilde;o, comodato ou garantia, doar, alienar de qualquer forma, transferir, emprestar ou ceder, total ou parcialmente, sob quaisquer modalidades, gratuita ou
        onerosamente, provis&oacute;ria ou permanentemente, o software, nem permitir seu uso por terceiros, a qualquer t&iacute;tulo, assim como seus manuais ou quaisquer informa&ccedil;&otilde;es relativas ao mesmo;
      </p>
      <p>&nbsp;</p>
      <p>
        17.1.2 &ndash; Modificar as caracter&iacute;sticas do software, m&oacute;dulo(s) de programa(s), arquivos ou rotinas dos sistemas ou de seus programas acess&oacute;rios, ampli&aacute;-los ou alter&aacute;-los de qualquer forma, sem a
        pr&eacute;via, expressa e espec&iacute;fica anu&ecirc;ncia da Contratada, ficando acertado que quaisquer altera&ccedil;&otilde;es, a qualquer tempo, por interesse do Contratante, que devam ser efetuadas nos sistemas, s&oacute;
        poder&atilde;o ser operadas pela Contratada ou pessoa expressamente autorizada pela mesma, sendo o resultado final considerado como parte do softwares da Contratada, ficando portanto sua propriedade incorporada pela Contratada, e seu
        uso condicionado &agrave;s presentes cl&aacute;usulas contratuais. &Eacute; vedada a engenharia reversa, bem como decompilar ou decompor o programa;
      </p>
      <p>&nbsp;</p>
      <p>
        17.1.2.1 &ndash; Caso o Contratante venha a desenvolver um novo sistema ou produto que caracterize c&oacute;pia, de todo ou em parte, quer seja dicion&aacute;rio de dados, quer seja do programa, ser&aacute; considerado como parte do
        software fornecido pela Contratada, ficando portanto sua propriedade incorporada pela Contratada, e seu uso condicionado &agrave;s presentes cl&aacute;usulas contratuais.
      </p>
      <p>&nbsp;</p>
      <p>
        17.1.3 &ndash; Revelar, duplicar, copiar ou reproduzir, autorizar ou permitir o uso ou dar conhecimento a terceiros do material fornecido pela Contratada, seja ele de cunho t&eacute;cnico, operacional ou de qualquer outra natureza, como
        por exemplo, mas n&atilde;o se limitando a estes, quaisquer dados, materiais, documentos, especifica&ccedil;&otilde;es t&eacute;cnicas ou comerciais, ou dados gerais em raz&atilde;o do presente contrato, de que venha a ter acesso ou
        conhecimento, ou ainda que lhes tenha sido confiados, ficando, neste caso, respons&aacute;vel pela utiliza&ccedil;&atilde;o indevida destas informa&ccedil;&otilde;es.
      </p>
      <p>&nbsp;</p>
      <p>
        17.2 &ndash; Os nomes, marcas, logotipos e demais signos distintivos da Contratada, existentes nas embalagens, manuais e no software a que se refere os servi&ccedil;os do presente contrato, n&atilde;o poder&atilde;o ser adulterados ou
        modificados, bem como n&atilde;o poder&atilde;o ser objeto de venda, licenciamento, loca&ccedil;&atilde;o, comodato, doa&ccedil;&atilde;o, transfer&ecirc;ncia ou transmiss&atilde;o onerosa ou gratuita, salvo pr&eacute;via e expressa
        anu&ecirc;ncia por escrito da Contratada.
      </p>
      <p>&nbsp;</p>
      <p>
        17.3 &ndash; &Eacute; vedada a supress&atilde;o, adultera&ccedil;&atilde;o ou altera&ccedil;&atilde;o total ou parcial dos nomes, marcas, logotipos e demais signos distintivos da Contratada, bem como a omiss&atilde;o ou
        informa&ccedil;&atilde;o deturpada no caso de divulga&ccedil;&atilde;o, por qualquer meio, em rela&ccedil;&atilde;o aos direitos autorais sobre o programa.
      </p>
      <p>&nbsp;</p>
      <p>
        17.4 &ndash; O Contratante comunicar&aacute; imediatamente &agrave; Contratada, no prazo de 24 (vinte e quatro) horas, sobre a exist&ecirc;ncia de quaisquer a&ccedil;&otilde;es judiciais, ou procedimentos extrajudiciais, relativos
        &agrave; propriedade intelectual do software, nomes, marcas, logotipos e demais signos distintivos, deixando sob o exclusivo controle da Contratada a defesa que se fizer necess&aacute;ria.
      </p>
      <p>&nbsp;</p>
      <p>
        17.5 &ndash; O Contratante reconhece que a n&atilde;o observ&acirc;ncia de quaisquer destas obriga&ccedil;&otilde;es configurar&aacute; viola&ccedil;&atilde;o da legisla&ccedil;&atilde;o aplic&aacute;vel ao direito autoral e &agrave;
        utiliza&ccedil;&atilde;o de software, submetendo-se, o Contratante e seus representantes legais, prepostos, empregados, gerentes, procuradores, sucessores e/ou terceiros interessados, &agrave;s san&ccedil;&otilde;es c&iacute;veis e
        penais cab&iacute;veis.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA D&Eacute;CIMA OITAVA &ndash; DA INEXIST&Ecirc;NCIA DE EXCLUSIVIDADE</strong></p>
      <p>&nbsp;</p>
      <p>
        18.1. A Contratante declara e reconhece que a Contratada presta servi&ccedil;os diversos, de natureza semelhante &agrave;queles prestados no &acirc;mbito deste contrato, a outros clientes, e concorda que nenhuma das
        disposi&ccedil;&otilde;es do presente instrumento poder&aacute; ser interpretada no sentido de impedir que a Contratada d&ecirc; seguimento a tais neg&oacute;cios. De modo espec&iacute;fico, a Contratante concorda, sem preju&iacute;zo
        de qualquer dispositivo em contr&aacute;rio aqui contido, que a Contratada ter&aacute; o direito de desenvolver, usar e distribuir trabalhos que realizem fun&ccedil;&otilde;es id&ecirc;nticas ou similares &agrave;quelas associadas aos
        servi&ccedil;os constantes no presente instrumento, sem, com isso, infringir quaisquer direitos da Contratante.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA D&Eacute;CIMA NONA &ndash; DAS DISPOSI&Ccedil;&Otilde;ES GERAIS</strong></p>
      <p>&nbsp;</p>
      <p>
        19.1 A falta de aplica&ccedil;&atilde;o das san&ccedil;&otilde;es previstas neste Contrato, bem como a absten&ccedil;&atilde;o ao exerc&iacute;cio de qualquer direito aqui conferido &agrave;s Partes, ser&aacute; considerada atos de mera
        toler&acirc;ncia e n&atilde;o implicar&atilde;o nova&ccedil;&atilde;o ou ren&uacute;ncia a direito, podendo as Partes exerc&ecirc;-los a qualquer momento.
      </p>
      <p>&nbsp;</p>
      <p>19.2 Este Contrato estabelece o acordo definitivo das Partes a respeito do seu objeto, revogando todos os entendimentos e acordos anteriores entre as Partes porventura existentes.</p>
      <p>&nbsp;</p>
      <p>
        19.3 A nulidade ou inaplicabilidade de qualquer disposi&ccedil;&atilde;o ou cl&aacute;usula n&atilde;o afeta ou invalida &agrave;s demais, devendo a cl&aacute;usula declarada nula ou inaplic&aacute;vel ser substitu&iacute;da por outra
        que conduza as Partes aos mesmos resultados econ&ocirc;micos e jur&iacute;dicos almejados.
      </p>
      <p>&nbsp;</p>
      <p>
        19.4 Na ocorr&ecirc;ncia de liquida&ccedil;&atilde;o, fal&ecirc;ncia, nos casos de dissolu&ccedil;&atilde;o judicial ou extrajudicial ou abandono das atividades de inform&aacute;tica pela Contratada, ou na eventual impossibilidade de a
        Contratada continuar suas atividades, deixando de comercializar o software, sem deixar sucessora, esta se compromete a entregar todos dados inseridos na plataforma pelo Contrante para o mesmo. O Contratante ser&aacute; o
        respons&aacute;vel pela coleta, armazenamento e tratamento dos dados pessoais de seus pacientes. Ainda, o Contratante declara que utilizar&aacute; os dados pessoais dos pacientes dentro da legisla&ccedil;&atilde;o e vigor, inclusive,
        mas n&atilde;o se limitando, &agrave; Lei Geral de Prote&ccedil;&atilde;o de Dados (Lei 13.709/18) e as normas do Conselho Federal de Medicina.
      </p>
      <p>&nbsp;</p>
      <p>
        19.5 &ndash; As partes acordam que o valor m&aacute;ximo para qualquer indeniza&ccedil;&atilde;o ou danos havidos em decorr&ecirc;ncia do presente instrumento, exceto para viola&ccedil;&atilde;o de direitos autorais, n&atilde;o
        dever&aacute;, em nenhuma circunst&acirc;ncia, exceder a 50% (cinquenta por cento) da m&eacute;dia mensal dos valores de manuten&ccedil;&atilde;o dos &uacute;ltimos 3 (tr&ecirc;s) meses.
      </p>
      <p>&nbsp;</p>
      <p>
        19.6 Fica facultado &agrave; Contratada sacar Letras de C&acirc;mbio ou Duplicatas com vencimento &agrave; vista, representativas de qualquer d&eacute;bito e respectivos encargos, inclusive morat&oacute;rios, que n&atilde;o venham a ser
        pagos no seu respectivo vencimento ou contra solicita&ccedil;&atilde;o, no caso de vencimento antecipado ou de n&atilde;o haver vencimento especificamente previsto na Proposta Comercial.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA VIG&Eacute;SIMA &ndash; DA DIVULGA&Ccedil;&Atilde;O DO NOME DO CLIENTE</strong></p>
      <p>&nbsp;</p>
      <p>
        20.1 &ndash; O Contratante autoriza a Contratada a divulgar e citar seu nome como Contratante, inclusive com publica&ccedil;&atilde;o do logotipo, sem pagamento de qualquer tipo de remunera&ccedil;&atilde;o, em materiais promocionais,
        &oacute;rg&atilde;os de comunica&ccedil;&atilde;o, sites, home-page, releases, presentations e quaisquer outros meios publicit&aacute;rios dispon&iacute;veis, bem como expressar o nome deste na lista de clientes da Contratada.
      </p>
      <p>&nbsp;</p>
      <p>
        20.2 &ndash; A divulga&ccedil;&atilde;o do nome da Contratada, tratada nesta cl&aacute;usula, n&atilde;o configura, em hip&oacute;tese alguma, viola&ccedil;&atilde;o &agrave;s obriga&ccedil;&otilde;es relacionadas ao sigilo e
        confidencialidade das informa&ccedil;&otilde;es.
      </p>
      <p>&nbsp;</p>
      <p><strong>CL&Aacute;USULA VIG&Eacute;SIMA PRIMEIRA &ndash; DA SOLU&Ccedil;&Atilde;O DE CONTROV&Eacute;RSIAS E DO FORO</strong></p>
      <p>&nbsp;</p>
      <p>
        21.1 Em caso de impasse no transcurso do presente instrumento as partes poder&atilde;o solucionar as suas quest&otilde;es por via de concilia&ccedil;&atilde;o ou media&ccedil;&atilde;o em uma Institui&ccedil;&atilde;o de livre escolha
        pelo prazo de 30 (trinta) dias.
      </p>
      <p>&nbsp;</p>
      <p>
        21.2 Ultrapassado o prazo acima estipulado ou por op&ccedil;&atilde;o de qualquer das partes, fica eleito o foro da Comarca de Belo Horizonte, Estado de Minas Gerais como o &uacute;nico competente para dirimir quaisquer quest&otilde;es
        pertinentes a este contrato, renunciando as Partes a qualquer outro, por mais privilegiado que seja.
      </p>
      <p>&nbsp;</p>

    </div>
  )
}