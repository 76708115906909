import React, { useEffect, useState } from 'react';
import './GruposPergunta.scss';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import { usePaciente } from '../../hooks/usePaciente';
import { GrupoPergunta } from '../../models/GrupoPergunta.model';
import { useGruposPergunta } from '../../hooks/useGruposPergunta';
import { finalizarScode, iniciarQuestionario } from '../../services/ScodeApiService';
import { useRespostaScode } from '../../hooks/useRespostaScode';
import Loading from '../../components/Loading';

import LogoScode from '../../assets/images/logoscode.svg';

type GrupoType = {
  grupo: GrupoPergunta,
  title: string
}

type GruposLocationType = {
  gruposPergunta: GrupoPergunta[]
}

export default function GruposPergunta() {
  const [ loading, setLoading ] = useState<boolean>(false); 
  const { getPaciente, getMenopausaResposta } = usePaciente();
  const { grupos, saveGrupos, saveGrupo } = useGruposPergunta();
  const { saveResposta, respostaScode } = useRespostaScode();
  const history = useHistory();

  useEffect(() => {
      obterGruposPergunta();
  }, []);


  const obterGruposPergunta = () => {
    setLoading(true);
    iniciarQuestionario(getPaciente(), getMenopausaResposta())
    .then(result => {
      if (result) {
        saveGrupos(result?.grupos);
        saveResposta(result?.resposta);
        if (questionarioFinalizado(result?.grupos)) {
          finalizarQuestionario(); 
        } 
      }
      setLoading(false);
    })
    .catch(error => setLoading(false));
  }

  const finalizarQuestionario = () => {
    setLoading(true);
    finalizarScode(respostaScode?.id)
      .then(result => {
        setLoading(false);
        history.push('/parabens');
      })
      .catch(error => setLoading(false));
  }

  const responderGrupo = (grupo: GrupoPergunta) => {
    saveGrupo(grupo);
    history.push('/perguntas');
  }

  const grupoFinalizado = (dado: GrupoType) => {
    return dado?.grupo?.quantidadeRespondida === dado?.grupo?.quantidadeTotal;
  }

  const questionarioFinalizado = (gruposPergunta: GrupoPergunta[] = grupos) => {
    const quantidadeRespondida = gruposPergunta.map(g => g.quantidadeRespondida).reduce((a: number, b: number) => a + b);
    const quantidadeTotal = gruposPergunta.map(g => g.quantidadeTotal).reduce((a: number, b: number) => a + b);
    return quantidadeRespondida === quantidadeTotal;
  }

  const Grupo = (dado: GrupoType) => {
    return (
      <div className="grupo-item" style={grupoFinalizado(dado)? {cursor: 'no-drop', opacity: 0.4} : {}}  onClick={() => { if (!grupoFinalizado(dado)) {responderGrupo(dado?.grupo)}}}>
        <div className="title">
          <div className="icon">
            <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 4.28571L0 10H20L13 2L8 7.71429L5 4.28571Z" fill="white"/>
              <circle cx="8.33341" cy="1.66667" r="1.66667" fill="white"/>
            </svg>                            
          </div>
            <h3>{dado?.title}</h3>
        </div>
        {
          grupoFinalizado(dado)? 
            <svg className="grupo-done" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.00003 11.17L1.83003 7L0.410034 8.41L6.00003 14L18 2L16.59 0.589996L6.00003 11.17Z" fill="#084C61"/>
            </svg>
          : 
          <p>{dado?.grupo?.quantidadeRespondida}/{dado?.grupo?.quantidadeTotal}</p>
        }
      </div>
    )
  }

  return (
    <section className="section-top">
      <Loading loading={loading} />
      {
        !loading ?
          
          <div className="grupo-container-scode">
            <div className="img-container-scode">
              <img src={LogoScode} alt="scode" title="scode" width="150" />
            </div>
            <h1>Selecione um grupo e responda</h1>
            <div className="grupos-list">
              {
                grupos?.length > 0? grupos.map((g, i) => <Grupo key={i} grupo={g} title={`Grupo de pergunta 0${i + 1}`} />) : null
              }
            </div>
          </div> : null
      }
    </section>
  )
}