import React, { useEffect } from 'react';
import './Pergunta.scss';
import {
  Formik,
  FormikHelpers,
  FormikProps,
  FormikErrors,
  Form,
  Field,
  FieldProps
} from 'formik';
import { useHistory } from 'react-router-dom';
import Button from '../Button';
import { PerguntaScode } from '../../models/Pergunta.model';
import { OpcaoPergunta } from '../../models/OpcaoPergunta.model';
import { ItemResposta } from '../../models/ItemResposta.model';

import LogoScode from '../../assets/images/logoscode.svg';

type PerguntaScodeType = {
  pergunta: PerguntaScode
  itemResposta: ItemResposta | undefined
  responderPergunta: (itemResposta: ItemResposta) => void
}

type FormField = {
  resposta: string;
}

type OpcaoType = {
  opcao: OpcaoPergunta,
  letra: string,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void 
}


const Pergunta: React.FC<PerguntaScodeType> = (props) => {
  const initialValues: FormField = {resposta: ''}
  const perguntaScode = props?.pergunta;
  const itemResposta = props?.itemResposta;
  const history = useHistory();
  const LETRAS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

  useEffect(() => {
    // console.log('pergunta', perguntaScode);
    // console.log('item resposta', itemResposta);
  }, [])

  const FieldOpcao = (dado: OpcaoType) => {
    return (
      <button type="button" className="choice-container-scode" value={`${dado?.opcao?.valor}`} 
       onClick={(event: any) => dado?.setFieldValue('resposta', dado?.opcao?.valor)}>
        <div className="option-choice">
            <span>{dado?.letra}</span>
        </div>
        <p>{dado?.opcao?.descricao}</p>
      </button>
    )
  }

  const salvarResposta = (value: string) => {
    const resposta = parseFloat(value === 'null'? '0' : value);
    let resp = Object.assign({} as ItemResposta, itemResposta);
    resp.resposta = resposta;
    props?.responderPergunta(resp);
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        let errors: FormikErrors<FormField> = {};        
        if (values.resposta === '') {
          errors.resposta = 'Você precisa selecionar uma das opções do formulário.';
        } 
        return errors;
      }}
      onSubmit={(values, actions) => {
        salvarResposta(values?.resposta);
      }}>
      {({touched, errors, handleChange, setFieldValue}) => (
      <Form className="radio-pergunta-form">
        <div className="img-container-scode">
              <img src={LogoScode} alt="scode" title="scode" width="150" />
        </div>
        <div className="pergunta-scode"><h2>{perguntaScode?.pergunta}</h2></div>
        {
          perguntaScode?.opcoesPerguntaScode?.length > 0? 

          <div className="question-type-container-scode" id="Pergunta">
           {perguntaScode?.opcoesPerguntaScode.map((o, i) => (<FieldOpcao key={`${i}`} opcao={o} letra={LETRAS[i]} setFieldValue={setFieldValue}/>))}
          </div>
          :
          <div className="questions-group">
            <div className="radio-group">
                <input type="radio" name="resposta" value={`${perguntaScode?.notaSim}`}
                onChange={(event) => {handleChange(event); }}
                />
                <label>Sim</label>
            </div>
            <div className="radio-group">
                <input type="radio" name="resposta" value={`${perguntaScode?.notaNao}`} 
                onChange={(event) => {handleChange(event); }}
                />
                <label>Não</label>
            </div>
            {touched.resposta && errors.resposta && <div className="msg-error">{errors.resposta}</div>}
          </div>
        }
        <div style={{display: 'flex', justifyContent:'space-between'}}>
          <Button style={{
              width: '100%', 
              margin: 5, 
              backgroundColor: '#fff', 
              color: '#084C61',
              fontWeight: 'bold',
              border: '2px solid #347D85'
              }} type="button" onClick={() => history.goBack()}
              >Voltar
          </Button>
          <Button style={{
              width: '100%', 
              margin: 5, 
              backgroundColor: '#084C61', 
              fontWeight: 'bold'
              }} type="submit">Próximo
          </Button>
        </div>
      </Form>)}
    </Formik>
  )
}

export default Pergunta;