import React, { ComponentType } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '../../assets/images/times-solid.svg';

type ModalType = {
  show: boolean,
  close: () => void,
  title: string,
  component: ComponentType
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 5),
    width:'70vw',
    height: '70%'
  },
  close: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: 10,
    cursor: 'pointer'
  }
}));

export default function ModalInfo({ component: Component, show = false, title, close }: ModalType) {
  const rootRef = React.useRef(null);
  const classes = useStyles();
  return (
    <>
      {
        show ?
          <Modal className={classes.modal}
            open={show}
            onClose={close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            container={() => rootRef.current}
          >
            <div className={classes.paper}>
              <div className={classes.close}>
                <button onClick={() => close()}>
                  <img src={Close} />
                </button>
              </div>
              <Component />
            </div>
          </Modal> : null
      }
    </>

  );
}