import { ComponentType } from 'react';
import { Route, Redirect } from 'react-router';

type ProtectedRouteProps = {
  isAuth: boolean,
  component: ComponentType,
  path: string
}

function ProtectedRoute({component: Component, isAuth, path, ...rest}: ProtectedRouteProps) {
  return (
    <Route {...rest} path={path} render={(props) => {
      if (isAuth) {
        return <Component />
      } else {
        return <Redirect to={{pathname: '/erro', state: { message: `Você não está autenticado!`}}} />
      } 
    }}/>
  )
}

export default ProtectedRoute;