import { ItemResposta } from './../models/ItemResposta.model';
import { Resposta } from './../models/Resposta.model';
import { GrupoPergunta } from './../models/GrupoPergunta.model';
import { Paciente } from './../models/Paciente.model';
import { ResumoQuestionario } from '../models/ResumoQuestionario.model';
import http from '../utils/http';

type RespostaGruposType = {
  resposta: Resposta,
  grupos: GrupoPergunta[]
}

export const autenticarScode = (token: string | null) => {
  return http.post<Paciente | undefined>(`scode/autenticacao/`, {token}).then(result => result.data)
}

export const iniciarQuestionario = (paciente: Paciente | undefined, menopausa: string | undefined) => {
  return http.post<RespostaGruposType>(`scode/${paciente?.registroScodeId}/${paciente?.idPaciente}/questionario`, paciente, {params: {menopausa}}).then(result => result.data);
}

export const salvarRespostaScode = (respostaScodeId: string | undefined, itemResposta: ItemResposta) => {
  return http.put<ItemResposta>(`scode/respostas/${respostaScodeId}/${itemResposta.id}`, itemResposta).then(result => result.data);
}

export const finalizarScode = (respostaScodeId: string | undefined) => {
  return http.put<Resposta>(`scode/respostas/${respostaScodeId}`).then(result => result.data);
}

export const atualizarDataNascimentoPaciente = (paciente: Paciente) => {
  return http.put<Paciente>(`scode/pacientes/${paciente.idPaciente}`, paciente).then(result => result.data);
}

export const getQuestionario = (registroScodeId: string) => {
  return http.get<ResumoQuestionario[]>(`scode/${registroScodeId}/questionario`).then(result => result.data);
}