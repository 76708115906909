import React, { FormEvent, useEffect, useState } from 'react';
import Button from '../../components/Button';
import './DataNascimento.scss';
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps
} from 'formik';
import { parseISO, differenceInCalendarYears, format, isValid } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { usePaciente } from '../../hooks/usePaciente';
import { atualizarDataNascimentoPaciente } from '../../services/ScodeApiService';
import { Paciente } from '../../models/Paciente.model';
import Loading from '../../components/Loading';

type FormField =  {
  dataNascimento: string | undefined;
}

export default function DataNascimento() {
  const { getPaciente, saveDataNascimentoResposta, getDataNascimento, savePaciente } = usePaciente();
  const [idade, setIdade] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<FormField>({dataNascimento: getDataNascimento()})
  const [ dataAlterada, setDataAlterada ] = useState<Boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);
  const history = useHistory();
  
  useEffect(() => {
    if (getDataNascimento()) {
      calcularIdade(getDataNascimento());
    }
  }, []);

  const calcularIdade = (data: string = '') => {
    const dataNascimento = parseISO(data);
    const idade = differenceInCalendarYears(new Date(), dataNascimento);
    if (idade > 0) {
      setIdade(idade);
    }
  }

  const atualizarDataNascimento = (dataNascimento: Date) => {
    if (isValid(dataNascimento)) {
      setLoading(true);
      let pacienteModel = Object.assign({} as Paciente, getPaciente());
      pacienteModel.pessoa.dataNascimento = format(dataNascimento, 'yyyy-MM-dd');
      
      atualizarDataNascimentoPaciente(pacienteModel)
        .then(result => {
          savePaciente(result);
          saveDataNascimentoResposta(pacienteModel.pessoa.dataNascimento);
          setInitialValues({dataNascimento: pacienteModel.pessoa.dataNascimento});
          setDataAlterada(false);
          calcularIdade(pacienteModel.pessoa.dataNascimento);
          setLoading(false);
        })
        .catch(error => {setLoading(false);});
    } else {
      //Informar erro
    }
  }

  const enviar = () => {
    if (getPaciente()?.pessoa.sexo == 'F') {
      history.push('/menopausa');
    } else {
      history.push('/grupos-pergunta');
    }
  }

  const salvar = (data: string = '') => {
    if (dataAlterada) {
      const dataAtualizada = parseISO(data);
      atualizarDataNascimento(dataAtualizada);
    }
  }

  return (
   
    <div className="section-centered">
       <Loading loading={loading} />
       {!loading ?
          <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            salvar(values?.dataNascimento || '');
          }}>
          {props => (
            <Form className="insert-data-form">
            <h1>Sua data de nascimento está correta?</h1>
            <p>Altere sua data de nascimento ou clique em próximo para continuar.</p>
            <Field style={{fontSize: '2em'}} name="dataNascimento" type="Date" placeholder="00/00/0000" onChange={(event: any) => {
              props?.setFieldValue('dataNascimento', event?.target?.value)
              setDataAlterada(true);
            } }/>
            <div style={{display: 'flex', justifyContent:'space-between'}}>
              <Button style={{
                  width: '100%', 
                  margin: 5, 
                  backgroundColor: '#fff', 
                  color: '#084C61',
                  fontWeight: 'bold',
                  border: '2px solid #347D85'
                  }} type="submit" disabled={!dataAlterada}>Alterar</Button>
              { idade > 0 ? 
                <Button style={{
                  width: '100%', 
                  margin: 5, 
                  backgroundColor: '#084C61', 
                  fontWeight: 'bold'
                  }} type="button" onClick={() => enviar()}>
                  Proximo
                </Button> : null }
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 50}}>
              { idade > 0 ? <p style={{fontSize: '2em'}}>{idade} anos</p> : null }
            </div>
            {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30}}>
            {idade > 0 && paciente?.idade !== idade.toString() ? 
            <p>Atenção! 
              A idade informada está diferente do seu cadastro no sistema,
              entre em contato com a clínica para atualizar seus dados!
            </p> : null}
            </div> */}
          </Form>
          )}
        </Formik> : null
       } 
      </div>
     
  )
}