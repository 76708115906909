import { createContext, useEffect, useState } from "react";
import { ContextProviderProps } from '../shared/types';
import { GrupoPergunta } from '../models/GrupoPergunta.model';

type GruposContextType = {
  grupos: GrupoPergunta[],
  grupo: GrupoPergunta | undefined,
  saveGrupos: (grupos: GrupoPergunta[]) => void,
  saveGrupo: (grupo: GrupoPergunta) => void
}

export const GruposContext = createContext<GruposContextType>({} as GruposContextType);

export function GruposContextProvider(props: ContextProviderProps) {
  const [grupos, setGrupos] = useState<GrupoPergunta[]>([]);
  const [grupo, setGrupo] = useState<GrupoPergunta>();

  useEffect(() => {
    getGrupos();
    getGrupo();
  }, [])

  const saveGrupos = (grupos: GrupoPergunta[]) => {
    if (grupos) {
      setGrupos(grupos);
      localStorage.setItem('gruposPergunta', JSON.stringify(grupos));
    }
  }

  const saveGrupo = (grupo: GrupoPergunta) => {
    if (grupo) {
      setGrupo(grupo);
      localStorage.setItem('grupoPergunta', JSON.stringify(grupo));
    }
  }

  const getGrupos = () => {
    if (grupos?.length > 0) return;
    const gruposStorage = localStorage.getItem('gruposPergunta');
    if (gruposStorage) {
      setGrupos(JSON.parse(gruposStorage));
    } 
  }

  const getGrupo = () => {
    if (grupo) return;
    const grupoStorage = localStorage.getItem('grupoPergunta');
    if (grupoStorage) {
      setGrupo(JSON.parse(grupoStorage));
    } 
  }


  return (
    <GruposContext.Provider value={{grupos, grupo, saveGrupos, saveGrupo}}>
      {props.children}
    </GruposContext.Provider>
  )
}