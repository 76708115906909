import React, { useEffect, useState } from 'react';
import './PerguntasPage.scss';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import { usePaciente } from '../../hooks/usePaciente';
import { PerguntaScode } from '../../models/Pergunta.model';
import { GrupoPergunta } from '../../models/GrupoPergunta.model';
import Pergunta from '../../components/Pergunta';
import { useGruposPergunta } from '../../hooks/useGruposPergunta';
import { salvarRespostaScode } from '../../services/ScodeApiService';
import { ItemResposta } from '../../models/ItemResposta.model';
import { useRespostaScode } from '../../hooks/useRespostaScode';
import Loading from '../../components/Loading';

type GrupoLocationType = {
  grupo: GrupoPergunta
}

export default function PerguntasPage() {
  const { saveResposta, itensResposta, respostaScode } = useRespostaScode();
  const { grupo } = useGruposPergunta();
  const [perguntas, setPerguntas] = useState<PerguntaScode[]>([]);
  const [perguntaAtual, setPerguntaAtual] = useState<PerguntaScode>();
  const [itemResposta, setItemResposta] = useState<ItemResposta>();
  const [ loading, setLoading ] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (grupo) {
      const perguntasNaoRespondidas = grupo?.perguntas?.filter(p => !p.respondida);
      setPerguntas(perguntasNaoRespondidas);
      selecionarPergunta(perguntasNaoRespondidas);
    }
  }, []);
  
  const selecionarPergunta = (perguntasScode: PerguntaScode[] = perguntas) => {
    setPerguntaAtual(undefined);
    const pergunta = perguntasScode[Math.floor(Math.random()*perguntasScode?.length)];
    if (pergunta) {
      setPerguntaAtual(pergunta);
      const itemResposta = itensResposta.find(i => i.perguntaScodeId === pergunta?.id);
      setItemResposta(itemResposta);
    }
  }

  const responderPergunta = (itemResposta: ItemResposta) => {
    setLoading(true);
    setPerguntaAtual(undefined);
    salvarRespostaScode(respostaScode?.id, itemResposta)
      .then(result => {
        marcarPerguntaRespondida(result);
        setLoading(false);
      })
      .catch(error => {setLoading(false)});
  }

  const marcarPerguntaRespondida = (itemResposta: ItemResposta) => {
    let perguntasScode = [...perguntas];
    let pergunta = perguntasScode.find(p => p.id === itemResposta.perguntaScodeId);
    if (pergunta) pergunta.respondida = true;
    let perguntasNaoRespondidas = perguntasScode.filter(p => !p.respondida);
    if (perguntasNaoRespondidas?.length > 0) {
      setPerguntas(perguntasNaoRespondidas);
      selecionarPergunta(perguntasNaoRespondidas);
    } else {
      history.goBack();
    }
  }

  return (
      <section className="section-centered">
        <Loading loading={loading} />
        {
          !loading ?
            <> 
              {
                perguntaAtual? 
                <Pergunta 
                  pergunta={perguntaAtual} 
                  itemResposta={itemResposta} 
                  responderPergunta={responderPergunta}/> : null
              }
            </>
          : null
        }
      </section>
  )
}