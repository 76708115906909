import { useEffect, useState } from 'react';
import profileImg from '../../assets/images/profile.png';
import DoctorsScode from '../../assets/images/doctors.png';
import mface from '../../assets/images/mface.jpg';
import wface from '../../assets/images/wface.jpg';
import './Parabens.scss';
import { usePaciente } from '../../hooks/usePaciente';
import { useHistory } from 'react-router-dom';
import { Paciente } from '../../models/Paciente.model';
import http from '../../utils/http';

export default function Parabens() {
  const { getPaciente } = usePaciente();
  const [ paciente, setPaciente ] = useState<Paciente | undefined>(getPaciente());
  const [fotoUrl, setFotoUrl] = useState<string>(profileImg);
  const urlImages = process.env.REACT_APP_STORAGE_ENDPOINT + '/imagens';
  const history = useHistory();


  useEffect(() => {
    
  },[])

  return (
    <section className="section-centered dark-theme">
    <div className="congratulation-container-scode">
      <img src={DoctorsScode} />
      <h1>Parabéns!</h1>
      <p>Você concluiu o questionário do SCode<br></br>Entraremos em contato com você!</p>
      <p></p>
      <p><a href="/resumo">Resumo do Questionário</a></p>
    </div>
    </section>
  )
}