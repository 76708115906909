import React, { useEffect, useState } from 'react';
import './MenopausaPage.scss';
import { useAuth } from '../../hooks/useAuth';
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps
} from 'formik';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import { usePaciente } from '../../hooks/usePaciente';

import LogoScode from '../../assets/images/logoscode.svg';

type FormField = {
  menopausa: string;
}

export default function MenopausaPage() {
  const { saveMenoPausaResposta, getMenopausaResposta } = usePaciente();
  const initialValues: FormField = { menopausa: getMenopausaResposta() || 'false' };
  const history = useHistory();

  const avancar = (value: FormField) => {
    saveMenoPausaResposta(value?.menopausa);
    history.push('/grupos-pergunta');
  }

  const voltar = () => {
    history.goBack();
  }
  
  return (
    <div className="section-top">
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            avancar(values);
          }}>
          {props => (
          <Form className="radio-menopausa-form">
            <div className="img-container-scode">
              <img src={LogoScode} alt="scode" title="scode" width="150" />
            </div>
            <h1>Você possui diagnóstico de menopausa?</h1>
            <div className="questions-group">
              <div className="radio-group">
                  <Field type="radio" name="menopausa" value="true"
                  onChange={(event: any) => {
                    props.handleChange(event);
                    saveMenoPausaResposta(event?.target?.value)
                  }} />
                  <label>Sim</label>
              </div>
              <div className="radio-group">
                  <Field type="radio" name="menopausa" value="false"
                  onChange={(event: any) => {
                    props.handleChange(event);
                    saveMenoPausaResposta(event?.target?.value)
                  }} />
                  <label>Não</label>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent:'space-between'}}>
              <Button style={{
                  width: '100%', 
                  margin: 5, 
                  backgroundColor: '#fff', 
                  color: '#084C61',
                  fontWeight: 'bold',
                  border: '2px solid #347D85'
                  }} type="button" onClick={() => voltar()}>Voltar
              </Button>
              <Button style={{
                  width: '100%', 
                  margin: 5, 
                  backgroundColor: '#084C61', 
                  fontWeight: 'bold'
                  }} type="submit">Próximo
              </Button>
            </div>
          </Form>)}
        </Formik>
     </div>
  )
}