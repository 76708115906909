import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useParams } from 'react-router-dom'
import './Autenticacao.scss';
import { usePaciente } from '../../hooks/usePaciente';
import Loading from '../../components/Loading';

type AuthParams = {
  token: string;
}

export default function Autenticacao(props: any) {
  const [ loading, setLoading ] = useState<boolean>(false);
  const { loadPaciente, saveToken} = useAuth();
  const { savePaciente } = usePaciente();
  const history = useHistory();
  const authParams = useParams<AuthParams>(); 
  const token = authParams?.token;

  useEffect(() => {
    //props?.match?.params?.token;
    if (!token) {
      history.push('erro');
    } else {
      setLoading(true);
      saveToken(token);
      loadPaciente()
      .then(result => {
        setLoading(false);
        if (result) {
          savePaciente(result);
          if (result.statusScode === "Finalizado") {
            history.push({ 
              pathname: '/erro',
              state: {
                          title: `Olá, ${result.pessoa.nome}`,
                          message: `Você já respondeu o SCode e suas respostas já foram enviadas. 
                          Aguarde o retorno do seu médico`
                      }
             })
          } else {
            history.push('/bem-vindo');
          }
        }
        
      })
      .catch(error => {
        history.push({ 
        pathname: '/erro',
        state: {
                    message: `Seu link expirou! 
                    Favor entrar em contato com a clínica e solicitar 
                    novamente seu acesso ao questionário.`
                }
       }); 
       setLoading(false)}
       );
    }
  },[])

  

  return (
    <div className="section-centered">
      <Loading loading={loading} />
    </div>
  )
}