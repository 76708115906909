import axios,  {AxiosRequestConfig } from 'axios';

const headers = {
  'Content-Type': 'application/json',
  'ApiKey': '9a57c3745b7f40f3980bfcc649b7a6e0' //process.env.REACT_APP_SCODE_API_KEY
}

const http = axios.create({
  //baseURL: 'https://localhost:44352/api/v1/' //process.env.REACT_APP_SCODE_API_ENDPOINT,
  baseURL: 'https://scode.snakeapp.com/apicode/api/v1/' //process.env.REACT_APP_SCODE_API_ENDPOINT,
});

http.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = headers;
  //console.log('config axios', config);
  return config;
});

export default http;
