import { format, parseISO } from "date-fns";
import { createContext, useState } from "react"
import { Paciente } from '../models/Paciente.model';
import { ContextProviderProps } from '../shared/types';

type PacienteContextType = {
  getPaciente: () => Paciente | undefined,
  getDataNascimento: () => string | undefined,
  getMenopausaResposta: () => string | undefined,

  saveDataNascimentoResposta: (data: string) => void,
  savePaciente: (paciente: Paciente | undefined) => void,
  saveMenoPausaResposta: (menopausa: string) => void
}

export const PacienteContext = createContext<PacienteContextType>({} as PacienteContextType);

export function PacienteContextProvider(props: ContextProviderProps) {

  const savePaciente = (paciente: Paciente | undefined) => {
    if (paciente) {
      localStorage.setItem('paciente', JSON.stringify(paciente));
      const data = format(parseISO(paciente?.pessoa.dataNascimento), "yyyy-MM-dd");
      saveDataNascimentoResposta(data);
    }
  }

  const saveDataNascimentoResposta = (data: string) => {
    if (data) {
      localStorage.setItem('paciente.dataNascimentoResposta', data);
    }
  }

  const saveMenoPausaResposta = (menopausaResposta: string) => {
    if (menopausaResposta) {
      localStorage.setItem('paciente.menopausaResposta', menopausaResposta);
    }
  }

  const getPaciente = () => {
    const pacienteStorage = localStorage.getItem('paciente');
    return pacienteStorage? JSON.parse(pacienteStorage) : undefined;
  }

  const getDataNascimento = () => localStorage.getItem('paciente.dataNascimentoResposta') || undefined;

  const getMenopausaResposta = () => localStorage.getItem('paciente.menopausaResposta') || undefined;
  
  return (
    <PacienteContext.Provider value={
      { getPaciente,
        getMenopausaResposta,
        getDataNascimento,
        savePaciente,
        saveDataNascimentoResposta,
        saveMenoPausaResposta
      }}>
      {props.children}
    </PacienteContext.Provider>
  )
}