import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Header from './components/Header';
import BemVindo from './pages/BemVindo';
import Auth from './pages/Autenticacao';
import ErrorPage from "./pages/ErrorPage";
import { AuthContextProvider } from "./contexts/AuthContext";
import { PacienteContextProvider } from './contexts/PacienteContext';
import MenopausaPage from "./pages/MenopausaPage";
import GruposPergunta from "./pages/GruposPergunta";
import PerguntasPage from "./pages/PerguntasPage";
import Parabens from "./pages/Parabens";
import SaibaMais from "./pages/SaibaMais";
import { GruposContextProvider } from "./contexts/GruposPerguntaContext";
import { RespostaContextProvider } from "./contexts/RespostaContext";
import DataNascimento from "./pages/DataNascimento";
import ProtectedRoute from './utils/ProtectedRoute';
import { usePaciente } from "./hooks/usePaciente";
import ResumoQuestionario from "./pages/Resumo";

function App() {
  const { getPaciente } = usePaciente();
  const isAuth = getPaciente !== null;
  return (
      <BrowserRouter> 
        <RespostaContextProvider>
        <GruposContextProvider>
        <PacienteContextProvider>
        <AuthContextProvider>
            <Header />
            <Switch>
              <Route path="/" exact>
                <Redirect to="/erro" />
              </Route>
              <Route path="/auth/:token" component={Auth} />
              <ProtectedRoute path="/bem-vindo" component={BemVindo} isAuth={isAuth}/>
              <ProtectedRoute path="/data-nascimento" component={DataNascimento} isAuth={isAuth}/>
              <ProtectedRoute path="/menopausa" component={MenopausaPage} isAuth={isAuth}/>
              <ProtectedRoute path="/grupos-pergunta" component={GruposPergunta} isAuth={isAuth}/>
              <ProtectedRoute path="/perguntas" component={PerguntasPage} isAuth={isAuth}/>
              <ProtectedRoute path="/parabens" component={Parabens} isAuth={isAuth}/>
              <ProtectedRoute path="/saibamais" component={SaibaMais} isAuth={isAuth}/>
              <ProtectedRoute path="/resumo" component={ResumoQuestionario} isAuth={isAuth}/>
              <Route path="/erro" component={(props: any) => <ErrorPage {...props} />} />
              <Route children={<ErrorPage message={"Página não encontrada"} />} />
            </Switch>
        </AuthContextProvider>
        </PacienteContextProvider>
        </GruposContextProvider>
        </RespostaContextProvider>
      </BrowserRouter>
    );
}

export default App;
