import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  termoContent: {
    padding: 10,
    textAlign: 'justify',
    position: 'relative',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll'
  }
}));

export default function PoliticaPrivacidadePage() {
  const classNamees = useStyles();
  return (
    <div className={classNamees.termoContent}>
      <h1 style={{ fontWeight: 'bold' }}>O que é o SCODE</h1>
      <p><strong>&nbsp;</strong></p>
      <div className="brz-css-rucdf brz-wrapper">
        <div className="brz-rich-text brz-css-rebdo" data-custom-id="nustwzzfhiqhsrjvqptifknhkqradxbtlowu">
          <p><span className="brz-cp-color7">A </span><strong className="brz-cp-color7">Para que serve o SCODE</strong></p>
          <p><span className="brz-cp-color7"> 1 &ndash; Quem avalia o SCODE?</span></p>
          <p><span className="brz-cp-color7"> 2 &ndash; Quem mantém e desenvolve o SCODE?</span></p>
          <p><span className="brz-cp-color7"> 3 &ndash; Com quem a </span><strong className="brz-cp-color7">SNAKE</strong><span className="brz-cp-color7"> compartilha os dados?</span></p>
          <p><span className="brz-cp-color7"> 4 &ndash; Direitos dos titulares de dados.</span></p>
          <p><span className="brz-cp-color7"> 5 &ndash; Por quanto tempo a </span><strong className="brz-cp-color7">SNAKE</strong><span className="brz-cp-color7"> ir&aacute; armazenar os dados?</span></p>
          <p><span className="brz-cp-color7"> 6 &ndash; Como funciona a Seguran&ccedil;a da Informa&ccedil;&atilde;o na </span><strong className="brz-cp-color7">SNAKE</strong><span className="brz-cp-color7">.</span></p>
          <p>&nbsp;</p>
          <p><strong className="brz-cp-color7">1 &ndash; Quais s&atilde;o as fontes de dados</strong></p>
          
          <p className="brz-tp-paragraph"><span className="brz-cp-color7"> Como estamos sempre buscando melhorar nossos servi&ccedil;os, essa Pol&iacute;tica de Privacidade pode passar por atualiza&ccedil;&otilde;es. Desta forma, recomendamos visitar periodicamente esta p&aacute;gina para que voc&ecirc; tenha conhecimento sobre as modifica&ccedil;&otilde;es. Caso sejam feitas altera&ccedil;&otilde;es relevantes que ensejem em um novo consentimento seu, iremos publicar essa atualiza&ccedil;&atilde;o e solicitar um novo consentimento para voc&ecirc;. </span></p>
        </div>
        <div className="brz-rich-text brz-css-rebdo">
        <p>&nbsp;</p>
            <a href="bem-vindo">
                <svg width="30px" height="30px" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
                    <circle id="primary" cx="12" cy="12" r="10" fill="#3E969F"></circle>
                    <path id="secondary" d="M17,11H11V9.86a1,1,0,0,0-1.5-.69L6.38,11.31a.82.82,0,0,0,0,1.38L9.5,14.83a1,1,0,0,0,1.5-.69V13h6a1,1,0,0,0,0-2Z" fill="white"></path>
                    </svg>
            </a>
        </div>
      </div>
    </div>
  )
}