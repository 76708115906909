import { useEffect, useState } from 'react';
import profileImg from '../../assets/images/profile.png';
import logoScode from '../../assets/images/logo-scode.png';
import mface from '../../assets/images/mface.jpg';
import wface from '../../assets/images/wface.jpg';
import './BemVindo.scss';
import { usePaciente } from '../../hooks/usePaciente';
import { useHistory } from 'react-router-dom';
import { Paciente } from '../../models/Paciente.model';
import { withRouter } from 'react-router-dom'
import ModalInfo from '../../components/ModalInfo';
import TermoDeUsoPage from '../TermoDeUsoPage';
import PoliticaPrivacidadePage from '../PoliticaPrivacidadePage';
import { makeStyles, Modal } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}



function BemVindo() {
  const { getPaciente, getMenopausaResposta } = usePaciente();
  const [ paciente, setPaciente ] = useState<Paciente | undefined>(getPaciente());
  const [modalTermoUso, setModalTermoUso] = useState(false);
  const [modalPoliticaPrivacidade, setModalPoliticaPrivacidade] = useState(false);
  const ToggleModalTermoUso = () => setModalTermoUso(!modalTermoUso);
  const ToggleModalPoliticaPrivacidade = () => setModalPoliticaPrivacidade(!modalPoliticaPrivacidade);
  //const urlImages = process.env.REACT_APP_STORAGE_ENDPOINT + '/imagens';
  const urlImages = 'https://storagesnake.blob.core.windows.net/imagens';
  const history = useHistory();
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  useEffect(() => {
  },[])

  const obterFotoDefeault = () => {
    if (paciente?.pessoa.sexo === 'M') {
      return mface;
    } else {
      return wface;
    }
  }

  const startQuestionario = () => {
    history.push('/data-nascimento');
  }

  return (
    
    <section className="section-centered dark-theme">
        <div className="welcome-container-scode">
            <div className="profile-container-scode">
                <img src={paciente?.foto ? `${urlImages}/${paciente?.foto}` : obterFotoDefeault()} alt="Foto de Perfil" style={{width: 50, height: 50, borderRadius: 50}} />
                <div className="profile-data">
                    <h3>{paciente?.pessoa.nome}</h3>
                    <p className="desktop-name">{paciente?.nomeFuncionario} | {paciente?.nomeClinica}</p>
                    <p className="mobile-name">{paciente?.nomeFuncionario}</p>
                    <p className="mobile-name">{paciente?.nomeClinica}</p>
                </div>
            </div>
            <img src={logoScode} alt="Logo da Scode" />
            <h1>Bem-vindo ao SCode</h1>
            <p>Este é um questionário que auxilia seu médico na identificação do seu tratamento, responda todas as perguntas</p>
            <button className="btn-scode-default" 
              onClick={() => startQuestionario()}>
              Começar Questionário
            </button>
            <p className="privacy-policy-text">Ao clicar em ”Começar” você está de acordo com os <a onClick={() => ToggleModalTermoUso()}>Termos de Uso</a> e <a onClick={() => ToggleModalPoliticaPrivacidade()}>Políticas de Privacidade</a> da Snake</p>
        </div>
        <ModalInfo show={modalTermoUso} title={'Termo de Uso'} close={ToggleModalTermoUso} component={TermoDeUsoPage}/>
        <ModalInfo show={modalPoliticaPrivacidade} title={'Termo de Uso'} close={ToggleModalPoliticaPrivacidade} component={PoliticaPrivacidadePage}  />
    </section>
  )
}

export default withRouter(BemVindo);