import { useState } from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #347D85;
`;

type LoadingProps = {
  loading: boolean
}

export default function Loading({ loading = false, ...props }: LoadingProps) {
  let [color, setColor] = useState("#ffffff");

  return (
    <ClipLoader loading={loading} css={override} color={color} size={150} />
  );
}