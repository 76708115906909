import { useState, useEffect } from 'react';
import './ResumoQuestionario.scss';
import Loading from '../../components/Loading';
import { getQuestionario } from '../../services/ScodeApiService';
import { usePaciente } from '../../hooks/usePaciente';
import { Paciente } from '../../models/Paciente.model';
import { ResumoQuestionario } from '../../models/ResumoQuestionario.model';
import DoctorsScode from '../../assets/images/doctors.png';
import LogoScode from '../../assets/images/logoscode.svg';

export default function ErrorPage(props: any) {
  const [ loading, setLoading ] = useState<boolean>(false); 
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const { getPaciente } = usePaciente();
  const [ paciente, setPaciente ] = useState<Paciente | undefined>(getPaciente());  
  const [ resumo, setResumo ] = useState<ResumoQuestionario[] | undefined>();

  useEffect(() => {
    //console.log('props', props)
    setTitle('Resumo do questionário');
    const nome = paciente?.pessoa.nome;
    if(nome)
        setMessage(nome);
    if (props?.message) {
      setMessage(props?.message);
    } else {
      if (props?.location?.state?.message) {
        setMessage(props.location.state.message);
      }
      if (props?.location?.state?.title) {
        setTitle(props?.location?.state?.title);
      }
    }
    obterResumoQuestionario();
  },[])

  const obterResumoQuestionario = () => {
    setLoading(true);
    const id = paciente?.registroScodeId;
    if(id){
    getQuestionario(id)
    .then(result => {
      if (result) {
        setResumo(result);
      }
      setLoading(false);
    })
    .catch(error => setLoading(false));
  }
  }

  return (
    <section className="section-centered">
      <div>
        <div className="error-container-scode">

            <div className="img-container-scode">
              <img src={LogoScode} alt="scode" title="scode" width="150" />
            </div>
            <h5>{title}</h5>
            <h1>{message}</h1>
        </div>

        <div className="error-container-scode">
          {resumo?.map((item, index) => (
            <div key={index} className="row">              
              <div className="block-e">{item.pergunta} </div><div className="block-d"> {item.resposta}</div>
            </div>
          ))}

        </div>
        </div>

    </section>
  )
}