import { createContext, ReactNode, useState } from "react"
import { useHistory } from 'react-router-dom';
import { Paciente } from '../models/Paciente.model';
import { autenticarScode } from '../services/ScodeApiService';
import { ContextProviderProps } from '../shared/types';
import { usePaciente } from '../hooks/usePaciente';

type AuthContextType = {
  loadPaciente: () => Promise<Paciente | undefined>;
  saveToken: (token: string) => void;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthContextProvider(props: ContextProviderProps) {
  const history = useHistory();

  const loadPaciente = async () => {
    const token = getToken();
    if (!token) {
      history.push('erro');
      return;
    }
    return autenticarScode(token)
    .then(result => result)
  }
  
  const saveToken = (token: string) => {
    localStorage.setItem('token', token);
  }

  const getToken = () => localStorage.getItem('token');

  
  return (
    <AuthContext.Provider value={{ loadPaciente, saveToken}}>
      {props.children}
    </AuthContext.Provider>
  )

}