import { useState } from 'react';
import { useEffect } from 'react';
import './ErrorPage.scss';
import LogoScode from '../../assets/images/logoscode.svg';

export default function ErrorPage(props: any) {
  const [message, setMessage] = useState('Algo deu errado');
  const [title, setTitle] = useState('Ops!');

  useEffect(() => {
    //console.log('props', props)
    if (props?.message) {
      setMessage(props?.message);
    } else {
      if (props?.location?.state?.message) {
        setMessage(props.location.state.message);
      }
      if (props?.location?.state?.title) {
        setTitle(props?.location?.state?.title);
      }
    }
    
  },[])

  return (
    <section className="section-centered">
        <div className="error-container-scode">
        <div className="img-container-scode">
              <img src={LogoScode} alt="scode" title="scode" width="150" />
            </div>
            <h1>{title}</h1>
            <p>{message}</p>
            <p><a href="/resumo">Resumo do Questionário</a></p>
        </div>
    </section>
  )
}