import { createContext, useEffect, useState } from "react";
import { ContextProviderProps } from '../shared/types';
import { GrupoPergunta } from '../models/GrupoPergunta.model';
import { Resposta } from '../models/Resposta.model';
import { ItemResposta } from "../models/ItemResposta.model";

type RespostaContextType = {
  respostaScode: Resposta | undefined,
  itensResposta: ItemResposta[],
  saveResposta: (resposta: Resposta) => void
}

export const RespostaContext = createContext<RespostaContextType>({} as RespostaContextType);

export function RespostaContextProvider(props: ContextProviderProps) {
  const [respostaScode, setRespostaScode] = useState<Resposta>();
  const [itensResposta, setItensResposta] = useState<ItemResposta[]>([]);

  useEffect(() => {
    getRespostaScode();
    getItensResposta();
  }, [])

  const saveResposta = (resposta: Resposta) => {
    if (resposta) {
      setRespostaScode(resposta);
      localStorage.setItem('respostaScode', JSON.stringify(resposta));
      saveItensResposta(resposta.itensRespostasScode);
    }
  }

  const saveItensResposta = (itensResposta: ItemResposta[]) => {
    if (itensResposta.length > 0) {
      setItensResposta(itensResposta);
      localStorage.setItem('itensRespostaScode', JSON.stringify(itensResposta));
    }
  }

  const getItensResposta = () => {
    if (itensResposta?.length > 0) return;
    const itensRespostaStorage = localStorage.getItem('itensRespostaScode');
    if (itensRespostaStorage) {
      setItensResposta(JSON.parse(itensRespostaStorage));
    } 
  }

  const getRespostaScode = () => {
    if (respostaScode) return;
    const respostaScodeStorage = localStorage.getItem('respostaScode');
    if (respostaScodeStorage) {
      setRespostaScode(JSON.parse(respostaScodeStorage));
    } 
  }


  return (
    <RespostaContext.Provider value={{respostaScode, itensResposta, saveResposta}}>
      {props.children}
    </RespostaContext.Provider>
  )
}